import React, { Component } from "react";

class AITermsOfUse extends Component {
  render() {
    return (
      <div>
        {/* Header Navbar */}
        <div className="app-header header--transparent sticker" id="main-menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-5 col-5">
                <div className="logo">
                  <a href={`${process.env.PUBLIC_URL}/`}>
                    <img
                      className="logo-1"
                      src={require("../assets/images/logo/logo.png").default}
                      alt="app landing"
                    />
                    <img
                      className="logo-2"
                      src={require("../assets/images/logo/logo-2.png").default}
                      alt="app landing"
                    />
                    <img
                      className="logo-3"
                      src={require("../assets/images/logo/logo-3.png").default}
                      alt="app landing"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-8 d-none d-lg-block">
                <div className="mainmenu-wrapper">
                  <nav>
                    <ul className="main-menu">
                      <li>
                        <a href="/ai-mate-privacy-policy">
                          AI Mate's Privacy Policy
                        </a>
                      </li>
                      <li className="active">
                        <a href="/ai-mate-terms-of-use">AI Mate's Terms Of Use</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-sm-7 col-7 d-block d-lg-none">
                <div className="mobile-menu">
                  <nav>
                    <ul>
                      <li className="active">
                        <a href="/ai-mate-privacy-policy">AI Mate's Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/ai-mate-terms-of-use">AI Mate's Terms Of Use</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* breadcrumb Area */}
        <div className="breadcrumb-area ptb--30 " data-black-overlay="9">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h2 className="title">AI Mate's Terms Of Use</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details">
                    <div class="container">
                      <p className="last_upd">Last Updated: March 31, 2023</p>
                      <br />
                      <p>
                        PLEASE READ THIS END-USER LICENSE AGREEMENT CAREFULLY
                        BEFORE ACCESSING OR USING the AI Mate APP, for this
                        End-User License Agreement governs your use of the AI
                        Mate App and how we provide the AI Mate App.
                      </p>
                      <br />
                      <ol>
                        <li>
                          The ChatBot - AI Mate - uses APIs to provide conversational
                           and Image creating AI functionalities. By using our application, you agree
                          to be bound by Terms of use <a href="https://openai.com/policies/terms-of-use">
                          <button>OpenAI's terms of use</button>
                        </a> as well.
                        </li>
                        <li>
                          Our ChatBot - AI Mate is intended for general informational
                          purposes only and should not be used as a substitute
                          for professional advice.
                        </li>
             
                        <li>
                          We are not responsible for any damages or losses that
                          may arise from the use of our ChatBot-AI-Mate, including but
                          not limited to errors or omissions in the content
                          provided by the ChatBot.
                        </li>   
                        <li>
                          We reserve the right to modify or terminate our
                          ChatBot and its terms of use at any time without prior
                          notice.
                        </li>
                      </ol>
                      <br />
                      <h4>A. AGREEMENT</h4>
                      <br />
                      <p>
                        This End-User License Agreement (including the
                        Supplemental Terms, as applicable) ("EULA") which
                        governs your use of the AI Mate App is a legal agreement
                        between you and IAC Search and Media Europe, Ltd., an
                        Irish registered company with company number 373923 and
                        its registered office at 10 Earlsfort Terrace, Dublin 2,
                        D02 T380, Ireland. The terms "<strong>AI Mate</strong>",
                        "<strong>we</strong>", "<strong>us</strong>" or "
                        <strong>our</strong>" used in this EULA refer to IAC
                        Search and Media Europe, Ltd. By installing or otherwise
                        using the AI Mate App, you: (a) agree to be bound by the
                        terms and conditions of this EULA, (b) you represent and
                        warrant that you own or control the mobile device in
                        which the AI Mate App will be installed, and (c) you
                        represent and warrant that you have the right, authority
                        and capacity to enter into this EULA and to abide by all
                        its terms and conditions, just as if you had signed it.
                        The terms and conditions of this EULA also apply to the
                        AI Mate App updates, supplements, and services that are
                        not provided under a separate license or other agreement
                        with us.&nbsp;
                        <strong>
                          If you do not agree to the terms and conditions of
                          this EULA, do not install or use the AI Mate App.
                        </strong>{" "}
                        We may amend these terms and conditions from time to
                        time. If the changes include material changes that
                        affect your rights or obligations, we will notify you of
                        the changes by reasonable means. You acknowledge that an
                        in-app message which notifies you of such changes when
                        you open up the AI Mate App shall constitute reasonable
                        means. Your continued use of the AI Mate App after we
                        post any amendments to this EULA will signify your
                        acceptance of such amendments.&nbsp;
                        <strong>
                          If you do not agree with any amendment, you must
                          discontinue using the AI Mate App.
                        </strong>{" "}
                        If you have any questions or concerns regarding the
                        terms or conditions herein, please email us at{" "}
                        amazinproservice@gmail.com. Do not use the AI Mate App
                        until your questions and concerns have been answered to
                        your satisfaction and you agree to abide by the EULA.
                      </p>
                      <br />
                      <p>
                        <strong>Notice to consumers</strong>: Depending on the
                        laws of the jurisdiction where you live, you may have
                        certain rights that cannot be waived through this EULA
                        and that are in addition to the terms of this EULA, and
                        certain provisions of this EULA may be unenforceable as
                        to you. To the extent that any term or condition of this
                        EULA is unenforceable, the remainder of the EULA shall
                        remain in full force and effect.
                      </p>
                      <br />
                      <p>
                        Use of the AI Mate App is subject to our
                        <a href="https://www.amazinpro.com/ai-mate-youpro_policy">
                          <button> AI Mate's Privacy Policy </button>
                        </a>
                        , which is hereby incorporated into this EULA by
                        reference. This EULA also includes any additional
                        payment terms and other requirements set forth on the
                        download or purchase page through which you purchase or
                        download the AI Mate App. The AI Mate App may be
                        available through marketplaces that distribute mobile
                        applications and that may have additional terms,
                        conditions and usage rules that govern your use of the
                        AI Mate App if you download or install the AI Mate App
                        through such marketplaces.
                      </p>
                      <br />
                      <h4>
                        <a name="b" />
                        B. AGE REQUIREMENT
                      </h4>
                      <br />
                      <p>
                        If you are at least 13 (or 16 in the EEA) but not yet 18
                        years of age, please have your parent or legal guardian
                        review this EULA with you, discuss any questions you
                        might have, and install the AI Mate App for you.
                      </p>
                      <p>
                        NOTICE TO PARENTS AND GUARDIANS: By granting your child
                        permission to download and access an the AI Mate App, you
                        agree to the terms and conditions of this EULA on behalf
                        of your child. You are responsible for exercising
                        supervision over your children's online activities. If
                        you do not agree to this EULA, do not let your child use
                        the AI Mate App or associated features. If you are the
                        parent or guardian of a child under 13 (or 16 in the
                        EEA) and believe that he or she is using the AI Mate
                        App, please contact us at amazinproservice@gmail.com.
                      </p>
                      <br />
                      <h4>
                        <a name="c" />
                        C. GRANT OF LICENSE
                      </h4>
                      <br />
                      <p>
                        Subject to your compliance with the terms and conditions
                        of this EULA, YouPro grants you a limited,
                        non-exclusive, revocable, non-sublicensable,
                        non-transferable license, to access, download and
                        install the most current generally available version of
                        the AI Mate App on a single, authorized mobile device
                        that you own or control solely for your lawful,
                        personal, and non-commercial entertainment use.
                      </p>
                      <br />
                      <h4>
                        <a name="d" />
                        D. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS
                      </h4>
                      <br />
                      <p>
                        <strong>
                          <a name="d1" />
                          1. Restricted Use
                        </strong>{" "}
                        You may not rent, sell, lease, sublicense, distribute,
                        assign, copy (other than a single copy for your own
                        backup purposes), or in any way transfer or grant any
                        rights to the AI Mate App or use the AI Mate App for the
                        benefit of any third party. Unless expressly authorized
                        by YouPro - AmazinPro or permitted under the applicable mobile
                        platform terms, you are prohibited from making the AI
                        Mate App available over a network where it could be
                        downloaded or used by multiple users. You agree that you
                        will not use any robot, spider, other automatic or
                        manual device or process to interfere or attempt to
                        interfere with the proper working of the AI Mate App,
                        except to remove our AI Mate App from a mobile device
                        which you own or control. You may not violate or attempt
                        to violate the security of our services. You may not
                        modify, reverse-engineer, decompile, disassemble, or
                        otherwise tamper with any AI Mate App, or attempt to do
                        so for any reason or by any means. You may not access,
                        create or modify the source code of any AI Mate App in
                        any way. You do not have the right to and may not create
                        derivative works of any AI Mate App or any portions
                        thereof. All modifications or enhancements to the AI
                        Mate App remain the sole property of YouPro of AmazinPro.
                      </p>
                      <br />
                      <p>
                        <strong>
                          <a name="d2" />
                          2. AI Mate App Updates
                        </strong>{" "}
                        We reserve the right to add or remove features or
                        functions to existing AI Mate App. When installed on
                        your mobile device, the AI Mate App periodically
                        communicate with our servers. We may require the
                        updating of the AI Mate App on your mobile device when
                        we release a new version of the AI Mate App, or when we
                        make new features available. This update may occur
                        automatically or upon prior notice to you, and may occur
                        all at once or over multiple sessions. You understand
                        that we may require your review and acceptance of our
                        then-current EULA before you will be permitted to use
                        any subsequent versions of the AI Mate App. You
                        acknowledge and agree that any obligation we may have to
                        support previous versions of the AI Mate App may be
                        ended upon the availability of updates, supplements or
                        subsequent versions of the AI Mate App. You acknowledge
                        and agree that we have no obligation to make available
                        to you any updates, supplements or subsequent versions
                        of the AI Mate Apps.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="d3" />
                          3. Access.
                        </strong>{" "}
                        You must provide at your own expense the equipment,
                        Internet connections, devices and service plans to
                        access and use the AI Mate App. If you access the AI Mate
                        App through a mobile network, your network or roaming
                        provider's messaging, data and other rates and fees may
                        apply. You are solely responsible for any costs you
                        incur to access the AI Mate App from your device.
                        Downloading, installing or using certain AI Mate App may
                        be prohibited or restricted by your network provider and
                        not all AI Mate App may work with your network provider
                        or device. YouPro makes no representation that the AI
                        Mate App can be accessed on all devices or wireless
                        service plans. YouPro makes no representation that the
                        AI Mate App are available in all languages or that the
                        AI Mate App are appropriate or available for use in any
                        particular location.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="d4" />
                          4. Purchases &amp; Cancellation Rights.
                        </strong>
                      </p>
                      <p>
                        <a name="da" />
                        (A). Subscriptions.
                      </p>
                      <p>
                        You may purchase for certain AI Mate App
                        subscription basis. The AI Mate App may offer different
                        subscription levels (usually free or premium). Payment
                        for a subscription (which may be for example monthly,
                        six-monthly or yearly) may be processed in App through a
                        third party mobile platform owner (e.g. Apple or Google)
                        or directly through YouPro and its third party payment
                        processor through an the AI Mate App website.
                      </p>
                      <p>
                        If you subscribed for an the AI Mate App through a third
                        party mobile platform owner (either through the third
                        party platform or in-app), you may cancel your
                        subscription at any time by following the Apple App
                        Store or Google Play Store instructions or via the
                        subscription settings in your iTunes or Android account.
                      </p>
                      <p>
                        All cancellations will take effect starting at the end
                        of the then current billing period. For example, if you
                        have a monthly subscription that began on the first of
                        the month but cancel mid-month, your cancellation will
                        be effective as of the end of that month. Sorry-- there
                        are no refunds on paid subscriptions once the charge has
                        gone through for the billing period.
                      </p>
                      <p>(B) Paid Applications</p>
                      <p>
                        Certain AI Mate App are available for one-time purchase
                        (no recurring subscription). Payment for such purchases
                        may be through a third party mobile platform provider
                        (e.g., Apple or Google). European Union residents
                        normally have a right to cancel online purchases within
                        14 days of making them.&nbsp;
                        <strong>
                          Please note and acknowledge: if you are resident in
                          the European Union and purchase an the AI Mate App from a
                          mobile platform owner (e.g. Apple or Google), you may
                          not be able to cancel your order or obtain a refund.
                          Please review the mobile platform owner's terms in
                          this regard before purchase.
                        </strong>
                      </p>
                      <p>
                        You may uninstall AI Mate App paid applications at any
                        time at your discretion. All purchases of paid
                        applications are non-refundable.
                      </p>
                      <p>
                        <br />
                        <strong>5. Automatic Renewal</strong>
                        <br /> Paid subscriptions to the AI Mate App are
                        auto-renewing. You will be charged the stated
                        subscription amount for the subscription period upon
                        completion of your registration and submission of your
                        payment information or, if applicable. You hereby agree
                        to and authorize automatic (recurring) billing, and you
                        agree to pay the charges made to your account in
                        connection therewith. You will be billed at the
                        then-current applicable subscription price plus any
                        applicable taxes. Payments for renewal subscriptions are
                        processed using the same billing cycle as your current
                        subscription. Subscription fees may change at any time,
                        to the fullest extent permitted under applicable law.
                        Your subscription, and monthly billing of your account,
                        will continue indefinitely until cancelled by you in
                        accordance with the Cancellation section of this
                        Agreement.
                      </p>
                      <br />
                      <h4>E.APP FUNCTIONALITY</h4>
                      <p>
                        AI Mate App allow you to enjoy various features,
                        functionalities and services, which may change from time
                        to time (collectively, the "
                        <strong>the AI Mate App Functions</strong>
                        "). The AI Mate App Functions are provided by YouPro and
                        third party suppliers who offer content and/or services
                        in conjunction with or through the AI Mate App (the "
                        <strong>Third Party Partners</strong>"). Neither YouPro,
                        nor any of its Third Party Partners, guarantee the
                        availability, accuracy, completeness, reliability, or
                        timeliness of location data displayed by any the AI Mate App.
                      </p>
                      <br />
                      <h4>
                        <a name="f" />
                        F. THIRD-PARTY PARTNERS:
                      </h4>
                      <br />
                      <p>
                        <strong>
                          <a name="f1" />
                          1. Third-Party Services and Content.
                        </strong>{" "}
                        The AI Mate App may integrate, be integrated into,
                        bundled, or be provided in connection with third-party
                        services, advertising, feeds, and/or content. If you are
                        installing an the AI Mate App that includes third party
                        services and third party content, such services and
                        content are subject to such third party's terms of
                        services and privacy policies, which may be found on the
                        relevant Third Party Partner's website. the AI Mate Apps may
                        provide access or links to Third Party Partner websites
                        or resources. YouPro has no control over such websites
                        and resources, and you acknowledge and agree that YouPro
                        is not responsible for the availability of such external
                        websites or resources, and does not endorse nor is
                        responsible or liable for any content, advertising,
                        products, or other materials on or available from such
                        websites or resources. You further acknowledge and agree
                        that YouPro shall not be responsible or liable, directly
                        or indirectly, for any damage or loss caused or alleged
                        to be caused by or in connection with use of or reliance
                        on any such Content (as defined below), goods or
                        services available on or through any such website or
                        resource. YouPro will not be a party to or in any way be
                        responsible for monitoring any transaction between you
                        and Third Party Partners.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="f2" />
                          2. Access to Third-Party Services and Content through
                          the AI Mate Apps
                        </strong>{" "}
                        All services, advertising, feeds and content, including
                        without limitation, all data, links, articles, graphic
                        or video messages and all information, text, software,
                        music, sound, graphics or other materials ("
                        <strong>Content</strong>") made available or accessible
                        through an the AI Mate App, whether publicly available or
                        privately transmitted, is the sole responsibility of the
                        entity or person from whom it originated. You hereby
                        acknowledge and agree that by using an the AI Mate App you
                        may be exposed to Content that may be offensive,
                        indecent or objectionable in your community. You agree
                        to accept all risks associated with the use of any
                        Content, including any reliance on the accuracy or
                        completeness of such Content. Under no circumstances
                        will YouPro be liable in any way for any Content created
                        by or originating with entities other ththe AI Mate,
                        including, but not limited to, any errors or omissions
                        in any such Content, or for loss or damage of any kind
                        incurred as a result of the transmission or posting of
                        such Content by means of an the AI Mate App.
                      </p>
                      <br />
                      <h4>
                        <a name="g" />
                        G. SECURITY
                      </h4>
                      <p>
                        AI Mate App, like other consumer technologies, may not
                        be 100% secure. By accepting this EULA you acknowledge
                        and accept that the AI Mate App and any information you
                        download or offer to share by means of an the AI Mate App,
                        may be exposed to unauthorized access, interception,
                        corruption, damage or misuse, and cannot be regarded as
                        100% secure. You accept all responsibility for such
                        security risks and any damage resulting therefrom.
                        Further, you are solely responsible for securing your
                        mobile device from unauthorized access, including by
                        such means as using complex password protection and, for
                        Android users, enabling device encryption in your
                        settings. You agree that YouPro shall not be liable for
                        any unauthorized access to your mobile device or the app
                        data thereon.
                      </p>
                      <br />
                      <h4>
                        <a name="h" />
                        H. REGISTRATION/PASSWORDS
                      </h4>
                      <p>
                        <br />
                        <strong>
                          <a name="h1" />
                          1. Registration.
                        </strong>{" "}
                        Most AI Mate App will not require a registration:
                        however, some AI Mate App may permit or require you to
                        create an account to participate or access additional
                        features or functionalities ("
                        <strong>Registration</strong>"). If such Registration is
                        required, it will be made known to you when you attempt
                        to participate or access such additional features or
                        functionalities. Any registration required by a Third
                        Party Partner is not governed by this EULA and you
                        should refer to the relevant Third Party Partner's
                        website for their policies.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="h2" />
                          2. Passwords.
                        </strong>{" "}
                        You are the sole and exclusive guardian of any password
                        and ID combination issued or chosen by to you.
                        Maintaining the confidentiality and security of your
                        password(s) and ID(s) is solely your responsibility. You
                        are fully responsible for all transactions undertaken by
                        means of any account opened, held, accessed or used via
                        your password and ID. You shall notify us immediately
                        and confirm in writing any unauthorized use of accounts
                        or any breach of security, including without limitation
                        any loss, theft or unauthorized use of your password(s),
                        and/or ID(s) or any related account. If we have
                        reasonable grounds to suspect that the security of your
                        password and/or ID has been compromised, we may suspend
                        or terminate your account, refuse any and all current or
                        future use of the services, and pursue any appropriate
                        legal remedies. We shall not be responsible for any
                        losses incurred in connection with any misuse of any
                        password or ID.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="h3" />
                          3. Provided Information.
                        </strong>{" "}
                        If you provide any information in connection with a
                        Registration, you must provide and maintain accurate,
                        complete and current information. If we have reasonable
                        grounds to suspect that your information is inaccurate,
                        not current or not complete, we may suspend or terminate
                        your use of the AI Mate App, and pursue any appropriate
                        legal remedies. You agree that we shall have the right
                        to use the information you provide to us for the
                        purposes described in this EULA and in furtherance of
                        your use of the AI Mate App our services, in accordance
                        with the Privacy Policy located{" "}
                        <a href="/privacy-policy"> here</a>
                      </p>
                      <br />
                      <h4>
                        <a name="i" />
                        I. UNINSTALL/REMOVAL
                      </h4>
                      <p>
                        Uninstallation and removal methods vary depending on
                        your device. To uninstall and remove the AI Mate App,
                        please use the application manager provided with your
                        device or consult your device manual for reference.
                        Please remember if you have a paid subscription to an
                        the AI Mate App, uninstalling will not cancel your recurring
                        payments. See our cancellation instructions above for
                        more information.
                      </p>
                      <h4>
                        <br />
                        <a name="j" />
                        J. CONSENT TO USE OF DATA AND USER REVIEWS
                      </h4>
                      <p>
                        You agree that we may collect and use technical data and
                        related information, including but not limited to
                        technical information about your device, system and
                        application software, and peripherals, that is gathered
                        periodically to facilitate the provision of software
                        updates, product support and other services to you (if
                        any) related to the AI Mate App. We may use this
                        information in accordance with the Privacy Policy
                        located <a href="/privacy-policy"> here</a>.
                      </p>
                      <p>
                        Additionally, if you choose to provide app store reviews
                        or reviews via any social medial channel, or other
                        similar communication or messaging features or services,
                        such information may be made publicly available,
                        including the public-facing username as it appears with
                        the review. If you prefer that we do not use your app
                        reviews for promotional purposes, you will be able to
                        elect for us not to do so by submitting your request{" "}
                        amazinproservice@gmail.com (please include your name,
                        mailing address, and email address). For security
                        purposes, please do not include any password, social
                        security number, payment card or other sensitive
                        information via these features. We have the right, but
                        not the obligation to monitor messages and
                        communications between and among users for security and
                        training purposes. We may, but are not obligated to,
                        remove any content we deem inappropriate.
                      </p>
                      <br />
                      <h4>
                        <a name="k" />
                        K. INTELLECTUAL PROPERTY
                      </h4>
                      <p>
                        The AI Mate App, including all design, text, images,
                        photographs, illustrations, audio-clips, video-clips,
                        artwork, graphic material, code, content, protocols,
                        software, and documentation provided to you by YouPro
                        are YouPro's property or the property of YouPro's
                        licensors, and are protected by U.S. and international
                        copyright, trademarks, patents and other proprietary
                        rights and laws relating to Intellectual Property
                        Rights. "<strong>Intellectual Property Rights</strong>"
                        means, collectively, rights under patent, trademark,
                        copyright and trade secret laws, and any other
                        intellectual property or proprietary rights recognized
                        in any country or jurisdiction worldwide, including,
                        without limitation, moral or similar rights. You may not
                        delete, alter, or remove any copyright, trademark, or
                        other proprietary rights notice we or Third Party
                        Partners have placed on or within the AI Mate App. All
                        rights not expressly granted hereunder are expressly
                        reserved to YouPro and its licensors.
                      </p>
                      <p>
                        The YouPro and YouPro names, logos and affiliated
                        properties, are the exclusive property of YouPro or its
                        affiliates. All other trademarks appearing on the AI
                        Mate App are trademarks of their respective owners, and
                        the use of such trademarks shall inure to the benefit of
                        the trademark owner. Our partners or service providers
                        may also have additional proprietary rights in the
                        content which they make available through an the AI Mate App.
                        The trade names, trademarks and service marks owned by
                        us, whether registered or unregistered, may not be used
                        in connection with any product or service that is not
                        ours, in any manner that is likely to cause confusion.
                        Nothing contained in herein should be construed as
                        granting, by implication, estoppel or otherwise, any
                        license or right to use any of our trade names,
                        trademarks or service marks without our express prior
                        written consent.
                      </p>
                      <br />
                      <h4>
                        <a name="l" />
                        L. COPYRIGHT AND CONTENT
                      </h4>
                      <p>
                        <strong>
                          <a name="l1" />
                          1. Copyright/Submissions.
                        </strong>{" "}
                        You are solely responsible for any Content you
                        contribute, submit, display or for any adaptations of
                        works made on or through your use of the AI Mate App(s).
                        It is your obligation to ensure that such Content,
                        including photos, text, video and music files, does not
                        violate any copyright or other Intellectual Property
                        Rights. You must either own or have a license to use any
                        Content that you contribute, submit or display.
                      </p>
                      <p>
                        YouPro respects and expects its users to respect the
                        rights of copyright holders. On notice, YouPro will act
                        appropriately to remove content that infringes the
                        copyright rights of others. YouPro reserves the right to
                        disable the access to AI Mate App or other services by
                        anyone who uses them to repeatedly infringe the
                        Intellectual Property Rights of others. If you believe
                        an the AI Mate App, or elements, infringe your copyright
                        rights, Please contact YouPro's Copyright Agent at:
                      </p>
                      <p>
                        IAC Search and Media Europe, Ltd.
                        <br />
                        10 Earlsfort Terrace
                        <br />
                        Dublin 2, D02 T380, Ireland
                        <br />
                        Attn: Copyright Agent/ Legal Dept.
                        <br />
                        Email:{" "}
                        <a href="mailto:amazinproservice@gmail.com">
                          amazinproservice@gmail.com
                        </a>
                      </p>
                      <p>
                        Please ensure your communication includes the following:
                      </p>
                      <ul className="list disc-type">
                        <li>
                          an electronic or physical signature of the person
                          authorized to act on behalf of the owner of the
                          copyright interest;
                        </li>
                        <li>
                          a description of the copyrighted work that you claim
                          has been infringed;
                        </li>
                        <li>
                          a description of where the material that you claim is
                          infringing is located on the AI Mate App;
                        </li>
                        <li>
                          your address, telephone number, and email address;
                        </li>
                        <li>
                          a statement by you that you have a good faith belief
                          that the disputed use is not authorized by the
                          copyright owner, its agent, or the law;
                        </li>
                        <li>
                          a statement by you that the above information in your
                          notice is accurate and that you are the copyright
                          owner or authorized to act on the copyright owner's
                          behalf.
                        </li>
                      </ul>
                      <p>
                        <strong>
                          <a name="l2" />
                          2. Objectionable Content.
                        </strong>{" "}
                        YouPro may also act to remove Objectionable Content. The
                        decision to remove Objectionable Content shall be made
                        at YouPro's sole discretion. "Objectionable Content"
                        includes, but is not limited to:
                      </p>
                      <ul className="list disc-type">
                        <li>
                          Content that is unlawful, harmful, threatening,
                          abusive, harassing, tortuous, defamatory, or libelous,
                        </li>
                        <li>
                          Content that is hateful, or advocates hate crimes,
                          harm or violence against a person or group,
                        </li>
                        <li>Content that may harm minors in any way;</li>
                        <li>
                          Content that has the goal or effect of "stalking" or
                          otherwise harassing another
                        </li>
                        <li>
                          Private information about any individual such as phone
                          numbers, addresses, Social Security numbers or any
                          other information that is invasive of another's
                          privacy;
                        </li>
                        <li>
                          Content that is vulgar, offensive, obscene or
                          pornographic,
                        </li>
                        <li>
                          Unsolicited or unauthorized advertising, promotional
                          materials, "junk mail," "spam," "chain letters,"
                          "pyramid schemes," or any other form of solicitation;
                        </li>
                        <li>
                          Material that contains software viruses or any other
                          computer code, files or programs designed to
                          interrupt, destroy or limit the functionality of any
                          computer software or hardware or telecommunications
                          equipment.
                        </li>
                      </ul>
                      <p>
                        <strong>
                          <a name="l3" />
                          3. Content Screening and Disclosure.
                        </strong>{" "}
                        We do not, and cannot, pre-screen or monitor all
                        Content. However, our representatives may monitor
                        Content submission through the AI Mate App, and you
                        hereby provide your irrevocable consent to such
                        monitoring. You acknowledge and agree that you have no
                        expectation of privacy concerning the submission of any
                        Content. We have the right, but not the obligation, in
                        our sole discretion to edit, refuse to post, or remove
                        any Content.
                      </p>
                      <p>
                        We may access, preserve or disclose any of your
                        information or Content (including without limitation
                        chat text) if we are required to do so by law, or if we
                        believe in good faith that it is reasonably necessary to
                        (i) respond to claims asserted against us or to comply
                        with legal process (for example, subpoenas or warrants),
                        including those issued by courts having jurisdiction
                        over us or you; (ii) enforce or administer our
                        agreements with users, such as this EULA; (iii) for
                        fraud prevention, risk assessment, investigation,
                        customer support, providing the app services or
                        engineering support; (iv) protect the rights, property
                        or safety of YouPro, its users, or members of the public
                        or (v) to report a crime or other offensive behaviour.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="l4" />
                          4. Ownership of Content You Submit.
                        </strong>{" "}
                        Unless otherwise set forth at the point of submission,
                        you retain ownership of all rights in any Content that
                        you submit, through your use of the AI Mate App.
                        However, you grant us permission to use such Content in
                        any way we see fit, for instance for the purposes of
                        promotion of the AI Mate App. If, at our request, you
                        send submissions (such as contest submissions, polling
                        questions) or you send us creative suggestions, ideas,
                        notes, drawings, or other information (collectively, the
                        "Submissions"), such Submissions shall be deemed, and
                        shall remain, the property of YouPro. None of the
                        Submissions shall be subject to any obligation of
                        confidence on the part of YouPro, and YouPro shall not
                        be liable for any use or disclosure of any Submissions.
                        Without limitation of the foregoing, YouPro shall
                        exclusively own all now known or hereafter existing
                        rights to the Submissions of every kind and nature
                        throughout the universe and shall be entitled to
                        unrestricted use of the Submissions for any purpose
                        whatsoever, commercial or otherwise, without
                        compensation to the provider of the Submissions. You
                        hereby assign to YouPro all right, title and interest in
                        and to the Submissions and you hereby waive any moral
                        rights (and any rights of the same or similar effect
                        anywhere in the world existing now or in the future
                        created) relating to the Submissions in favour of YouPro
                        and its assignees, licensees and designees.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="l5" />
                          5. Repeat Infringer Policy.
                        </strong>{" "}
                        YouPro may terminate a user's access to the YouPro
                        App(s) if, under appropriate circumstances, the user is
                        determined to be a repeat infringer.
                      </p>
                      <p>
                        <br />
                        <strong>
                          <a name="l6" />
                          6. No Intended Third Party Beneficiaries.
                        </strong>{" "}
                        Except as otherwise set forth herein, no third party is
                        an intended beneficiary of this EULA.
                      </p>
                      <br />
                      <h4>
                        <a name="m" />
                        M. TERMINATION
                      </h4>
                      <br />
                      <p>
                        Your rights under this EULA will terminate immediately
                        and automatically without any notice from YouPro if you
                        fail to comply with any of the terms and conditions of
                        this EULA. You understand that YouPro, in its sole
                        discretion, may modify or discontinue or suspend your
                        right to access any of our services or use of any AI
                        Mate App at any time. Further, YouPro, with or without
                        any reason, may at any time suspend or terminate any
                        license hereunder and disable the AI Mate App or any of
                        its component features. You agree that YouPro shall not
                        be liable to you or any third-party for any termination
                        or disabling of the AI Mate App. Promptly upon
                        expiration or termination of this EULA, you must cease
                        all use of the AI Mate Apps and destroy all copies of AI
                        Mate App in your possession or control. Termination will
                        not limit any of YouPro's other rights or remedies at
                        law or in equity. Sections J-S, and any Supplemental
                        Terms of this EULA shall survive termination or
                        expiration of this EULA for any reason.
                      </p>
                      <br />
                      <h4>
                        <a name="n" />
                        N. DISCLAIMER OF WARRANTY
                      </h4>
                      <p>
                        <br />
                        TO THE EXTENT THIS IS PERMITTED BY APPLICABLE LAW, ALL
                        YouPro APPS ARE PROVIDED ON AN "AS IS," "WITH ALL
                        FAULTS," AND "AS AVAILABLE" BASIS, AND YOU USE THEM AT
                        YOUR SOLE RISK. SUBJECT TO APPLICABLE LAW, YouPro, ON
                        BEHALF OF ITSELF, AND ITS AFFILIATES, LICENSORS,
                        DISTRIBUTORS, VENDORS, AGENTS AND SUPPLIERS, EXPRESSLY
                        DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER
                        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER
                        WARRANTY ARISING UNDER THE SALE OF GOODS ACTS 1893 AND
                        1980, USAGE OF TRADE, COURSE OF CONDUCT OR OTHERWISE.
                        WITHOUT LIMITATION, YouPro MAKES NO WARRANTY THAT THE
                        YouPro APPS WILL MEET YOUR REQUIREMENTS, THAT THEY WILL
                        BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT
                        THE RESULTS OBTAINED FROM THE USE OF THE YouPro PRODUCTS
                        WILL BE ACCURATE OR RELIABLE, OR THAT THE QUALITY OF THE
                        YouPro APPS WILL MEET YOUR EXPECTATIONS. YouPro ASSUMES
                        NO LIABILITY OR RESPONSIBILITY FOR ANY PROPERTY DAMAGE,
                        OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
                        AND USE OF OUR YouPro APPS; ANY UNAUTHORIZED ACCESS TO
                        OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                        INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN;
                        ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
                        YouPro APPS OR SERVERS; ANY BUGS, VIRUSES, TROJAN
                        HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                        THROUGH YouPro APPS BY ANY THIRD PARTY; OR ANY ERRORS OR
                        OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF
                        ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                        POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
                        AVAILABLE VIA THE YouPro APPS.
                      </p>
                      <p>
                        THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE
                        YouPro APPS REMAINS SOLELY WITH YOU.
                      </p>
                      <p>
                        YouPro EXPRESSLY DISCLAIMS ALL WARRANTIES RELATING TO
                        PRODUCTS AND/OR SERVICES PROVIDED BY THIRD PARTY
                        PARTNERS.
                      </p>
                      <p>
                        SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF
                        IMPLIED WARRANTIES. IN SUCH JURISDICTIONS, THE FOREGOING
                        DISCLAIMERS MAY NOT APPLY TO YOU INSOFAR AS THEY RELATE
                        TO IMPLIED WARRANTIES.
                      </p>
                      <p>
                        THIS DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL
                        PART OF THIS AGREEMENT.
                      </p>
                      <br />
                      <p>
                        <strong>
                          NOTICE REGARDING CALL RECORDING FEATURE:
                        </strong>{" "}
                        <br />
                        <br />
                        Certain AI Mate App may allow you to record phone
                        conversations on your Android or iOS device. Some local,
                        state, federal or international laws prohibit the
                        recording of third-party audio without all parties'
                        consent to such recording. You are solely responsible
                        for compliance with all local, state, federal or
                        international laws regarding call recording and
                        obtaining any necessary consent. IN NO EVENT SHALL
                        YouPro BE RESPONSIBLE TO YOU OR ANY THIRD PARTY FOR YOUR
                        FAILURE TO COMPLY WITH LOCAL, STATE, FEDERAL OR
                        INTERNATIONAL LAWS REGARDING THIRD-PARTY AUDIO
                        RECORDING.
                      </p>
                      <br />
                      <h4>
                        <a name="o" />
                        O. LIMITATION OF LIABILITY
                      </h4>
                      <br />
                      <p>
                        TO THE EXTENT PERMITTED BY APPLICABLE LAWS, YOU
                        EXPRESSLY UNDERSTAND AND AGREE THAT YouPro SHALL NOT BE
                        LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                        CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT
                        LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
                        DATA OR OTHER INTANGIBLE LOSSES (EVEN IF YouPro HAS BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
                        FROM: (I) THE USE OR THE INABILITY TO USE THE YouPro
                        APPS; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                        TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF
                        ANY THIRD PARTY; OR (IV) ANY OTHER MATTER RELATING TO
                        THE YouPro APPS. IN NO EVENT SHALL YouPro'S TOTAL
                        LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
                        ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT
                        LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT
                        PAID BY YOU, IF ANY, FOR ACCESSING THE YouPro APP. THE
                        FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE
                        STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                      </p>
                      <p>
                        IF ANY OF THE EXCLUSIONS SET FORTH IN THIS SECTION IS
                        DETERMINED BY A COURT OF COMPETENT JURISDICTION TO BE
                        UNENFORCEABLE, THEN ALL SUCH EXPRESS, IMPLIED AND
                        STATUTORY WARRANTIES SHALL BE LIMITED IN DURATION FOR A
                        PERIOD OF THIRTY (30) DAYS AFTER THE DATE ON WHICH YOU
                        FIRST ACCESS THE YouPro APP, AND NO WARRANTIES SHALL
                        APPLY AFTER SUCH PERIOD.
                      </p>
                      <h4>
                        <br />
                        <a name="p" />
                        P. INDEMNIFICATION
                      </h4>
                      <br />
                      <p>
                        YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS YouPro,
                        ITS PARENTS, AFFILIATE AND SUBSIDIARY COMPANIES,
                        OFFICERS, DIRECTORS, EMPLOYEES, CONSULTANTS AND AGENTS
                        FROM ANY AND ALL THIRD PARTY CLAIMS, LIABILITY, DAMAGES
                        AND/OR COSTS (INCLUDING, BUT NOT LIMITED TO, ATTORNEYS'
                        FEES) ARISING FROM YOUR USE OF THE YouPro APPS, YOUR
                        VIOLATION OF THE EULA OR YOUR INFRINGEMENT, OR
                        INFRINGEMENT BY ANY OTHER USER OF YOUR ACCOUNT, OF ANY
                        INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR
                        ENTITY. YOU AGREE TO IMMEDIATELY NOTIFY YouPro OF ANY
                        UNAUTHORIZED USE OF YOUR ACCOUNT OR ANY OTHER BREACH OF
                        SECURITY KNOWN TO YOU.
                      </p>
                      <br />
                      <h4>
                        <a name="q" />
                        Q. EXPORT CONTROLS
                      </h4>
                      <br />
                      <p>
                        The AI Mate App and the underlying information and
                        technology are subject to US and international laws,
                        restrictions and regulations that may govern the import,
                        export, downloading and use of the Apps. You agree to
                        comply with these laws, restrictions and regulations
                        when downloading or using the apps.
                      </p>
                      <h4>
                        <br />
                        <a name="r" />
                        R. NOTICE TO US GOVERNMENT END USERS
                      </h4>
                      <br />
                      <p>
                        Any AI Mate App installed for or on behalf of the United
                        States of America, its agencies and/or instrumentalities
                        ("U.S. Government"), is provided with Restricted Rights
                        as "commercial Items," as that terms is defined at 48
                        C.F.R. §2.101, consisting of "Commercial Computer
                        Software" and "Commercial Computer Software
                        Documentation," as such terms are used in 48 C.F.R.
                        §12.212 or 48 C.F.R. §227.7202, as applicable. Pursuant
                        to Federal Acquisition Regulation 12.212 (48 C.F.R.
                        §12.212), the U.S. Government shall have only those
                        rights specified in the license contained herein. The
                        U.S. Government shall not be entitled to (i) technical
                        information that is not customarily provided to the
                        public or to (ii) use, modify, reproduce, release,
                        perform, display, or disclose commercial computer
                        software or commercial computer software documentation
                        except as specified herein. Use, duplication, or
                        disclosure by the U.S. Government is subject to
                        restrictions as set forth in subparagraph (c)(1)(ii) of
                        the Rights in Technical Data and Computer Software
                        clause at DFARS 252.227-7013 or subparagraphs (c)(1) and
                        (2) of the Commercial Computer Software - Restricted
                        Rights at 48 C.F.R. 52.227-19, as applicable.
                      </p>
                      <h4>
                        <br />
                        <a name="s" />
                        S. JURISDICTIONAL AVAILABILITY
                      </h4>
                      <br />
                      <p>
                        YouPro does not represent or warrant that the AI Mate
                        App or any part thereof is appropriate or available for
                        use in any particular jurisdiction. We may limit the
                        availability of the AI Mate App, in whole or in part, to
                        any person, geographic area or jurisdiction we choose,
                        at any time and in our sole discretion.
                      </p>
                      <h4>
                        <br />
                        <a name="t" />
                        T. DISPUTE RESOLUTION AND OTHER MISCELLANEOUS TERMS
                      </h4>
                      <br />
                      <p>
                        Except for members residing within the European Economic
                        Area and elsewhere where prohibited by applicable law:
                      </p>
                      <p>
                        The exclusive means of resolving any dispute or claim
                        arising out of or relating to this EULA (including any
                        alleged breach thereof) or the AI Mate App shall be
                        BINDING ARBITRATION under the under the Rules of
                        Arbitration of the International Chamber of Commerce.
                        The number of arbitrators shall be one. The seat, or
                        legal place, of arbitration and the governing law shall
                        be Ireland. The language to be used in the arbitral
                        proceedings shall be English.
                      </p>
                      <p>
                        The one exception to the exclusivity of arbitration is
                        that either party has the right to bring an individual
                        claim against the other pursuant to a small claims
                        procedure, or, if filed in arbitration, the responding
                        party may request that the dispute proceed pursuant to a
                        small claims procedure if the party’s claim is eligible
                        for a small claims procedure. If the responding party
                        requests to proceed in a small claims procedure before
                        the appointment of the arbitrator, the arbitration shall
                        be administratively closed, and if requested after the
                        appointment of the arbitrator, the arbitrator shall
                        determine if the dispute should be decided in
                        arbitration or if the arbitration should be
                        administratively closed and decided through a small
                        claims procedure. Whether you choose arbitration or a
                        small-claims procedure, you may not under any
                        circumstances commence or maintain against the Company
                        any class action, class arbitration, or other
                        representative action or proceeding.
                      </p>
                      <br />
                      <p>
                        By using the AI Mate App in any manner, you agree to the
                        above arbitration agreement. In doing so, YOU GIVE UP
                        YOUR RIGHT TO GO TO COURT to assert or defend any claims
                        between you and the Company (except for matters that may
                        be undertaken through a small claims procedure). YOU
                        ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
                        OR OTHER CLASS PROCEEDING. Your rights will be
                        determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY,
                        and the arbitrator shall determine all issues regarding
                        the arbitrability of the dispute. You are entitled to a
                        fair hearing before the arbitrator. The arbitrator can
                        grant any relief that a court can, but you should note
                        that arbitration proceedings are usually simpler and
                        more streamlined than trials and other judicial
                        proceedings. Decisions by the arbitrator are enforceable
                        in court and may be overturned by a court only for very
                        limited reasons. Any proceeding to enforce this
                        arbitration agreement, including any proceeding to
                        confirm, modify, or vacate an arbitration award, may be
                        commenced in any court of competent jurisdiction. In the
                        event that this arbitration agreement is for any reason
                        held to be unenforceable, any litigation against the
                        Company (except for small-claims proceedings) may be
                        commenced only in the courts of Ireland. You hereby
                        irrevocably consent to the jurisdiction of those courts
                        for such purposes.
                      </p>
                      <p>
                        Governing Law. The laws of Ireland, without regard to
                        conflict of laws principles, shall govern all matters
                        relating to or arising from this EULA, and the use (or
                        inability to use) the AI Mate App.
                      </p>
                      <br />
                      <p>
                        The online dispute settlement platform of the European
                        Commission is available under http://ec.europa.eu/odr.
                        YouPro does not take part in dispute settlement
                        procedures in front of a consumer arbitration entity for
                        members residing in the EU or European Economic Area.
                      </p>
                      <p>
                        No failure or delay by YouPro in exercising any right,
                        power or privilege under this EULA will operate as a
                        waiver thereof, nor will any single or partial exercise
                        of any right, power or privilege preclude any other or
                        further exercise thereof or the exercise of any other
                        right, power, or privilege under this EULA. If any
                        provision of this EULA shall be found unlawful, void, or
                        for any reason unenforceable, then that provision shall
                        be deemed severable from these terms and shall not
                        affect the validity and enforceability of any remaining
                        provisions.
                      </p>
                      <br />
                      <p>
                        YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR
                        RELATED TO THE YouPro APPS MUST COMMENCE WITHIN THREE
                        (3) YEARS AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE,
                        SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                      </p>
                      <br />
                      <h4>
                        <a name="u" />
                        U. HOW TO CONTACT US.
                      </h4>
                      <br />
                      <p>
                        If you have any questions about this EULA, please
                        contact us by email or postal mail as follows:
                      </p>
                      <p>
                        IAC Search and Media Europe, Ltd.
                        <br />
                        Unit 1, College Business &amp; Technology Park,
                        Blanchardstown
                        <br />
                        15 Dublin
                        <br />
                        IRELAND
                        <br />
                        ATTN: Legal Dept.
                        <br />
                      </p>
                      <br />
                      <h4>
                        <a name="v" />
                        V. SUPPLEMENTAL TERMS
                      </h4>
                      <br />
                      <p>
                        To the extent permitted by applicable law (and without
                        limiting the above rights, remedies and obligations
                        except the extent expressly in conflict with additional
                        terms below), the following additional terms shall apply
                        to your use of the AI Mate Apps, as applicable:
                      </p>
                      <br />
                      <p>
                        Certain AI Mate App include content, footage and/or
                        images (collectively "Images") licensed to YouPro by
                        third parties such as Getty Images (US), Inc. or Adobe
                        Systems Inc. By utilizing these AI Mate App, you
                        specifically acknowledge and agree that:
                      </p>
                      <br />
                      <p>
                        <strong>1.</strong>&nbsp;You may only use the Images as
                        enabled by AI Mate App, for display in digital form or
                        as otherwise directed by AI Mate App. You shall not use
                        or distribute any portion of an image for any purpose
                        whatsoever in a way that violates any third party right,
                        or any applicable law, rule or regulation.
                      </p>
                      <br />
                      <p>
                        <strong>2.</strong>&nbsp;You may not sell or re-sell
                        Images, and may not modify or re-use Images, except as
                        enabled by the AI Mate App.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>3.</strong>&nbsp;Images may not be used:
                      </p>{" "}
                      <br />
                      <p>
                        <strong>a.</strong>&nbsp;for pornographic, defamatory or
                        other unlawful purposes;
                      </p>{" "}
                      <br />
                      <p>
                        <strong>b.</strong>&nbsp;for the purpose of enabling
                        file-sharing of the image file; or
                      </p>{" "}
                      <br />
                      <p>
                        <strong>c.</strong>&nbsp;in logos, trademarks, services
                        marks or any other branding or identifiers.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>4.</strong>&nbsp;If you use Images featuring an
                        individual(s) in connection with a sensitive,
                        unflattering or controversial subject, you must include
                        a statement that the image is used for illustrative
                        purposes only and the individual is a model.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>5.</strong>&nbsp;You may not remove any metadata
                        in Images, or reverse engineer, decompile, or
                        disassemble the AI Mate App to enable the download or
                        use of Images on a stand-alone basis.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>6.</strong>&nbsp;No ownership or copyrights to
                        any Images are granted to you.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>7.</strong>&nbsp;Images are provided to you on a
                        non-exclusive basis and you understand that other users
                        may create and use similar or identical images.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>
                          <a name="v5" />5 Youtube.
                        </strong>{" "}
                        Certain AI Mate App may incorporate or provide you with
                        access to Youtube content. By using such AI Mate App,
                        you agree that you will comply with Youtube's Terms of
                        Service available{" "}
                        <a
                          href="https://www.youtube.com/t/terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>
                        .
                      </p>{" "}
                      <br />
                      <p>
                        <strong>
                          <a name="v6" />
                          6. Apple App Store.
                        </strong>{" "}
                        By accessing the AI Mate App through a device made by
                        Apple, Inc. ("Apple"), you specifically acknowledge and
                        agree that:
                      </p>{" "}
                      <br />
                      <p>
                        <strong>1.</strong>&nbsp;This EULA is between YouPro and
                        you; Apple is not a party to this EULA.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>2.</strong>&nbsp;The license granted to you
                        hereunder is limited to a personal, limited,
                        non-exclusive, non-transferable right to install the
                        the AI Mate App on the Apple device(s) authorized by Apple
                        that you own or control for personal, non-commercial
                        use, subject to the Usage Rules set forth in Apple's App
                        Store Terms of Service.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>3.</strong>&nbsp;Apple is not responsible for
                        the AI Mate App or the content thereof and has no obligation
                        whatsoever to furnish any maintenance or support
                        services with respect to the AI Mate App.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>4.</strong>&nbsp;In the event of any failure of
                        the AI Mate App to conform to any applicable warranty,
                        you may notify Apple, and Apple will refund the purchase
                        price for the AI Mate App, if any, to you. To the maximum
                        extent permitted by applicable law, Apple will have no
                        other warranty obligation whatsoever with respect to the
                        the AI Mate App.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>5.</strong>&nbsp;Apple is not responsible for
                        addressing any claims by you or a third party relating
                        to the AI Mate App or your possession or use of the
                        the AI Mate App, including without limitation (a) product
                        liability claims; (b) any claim that the AI Mate App
                        fails to conform to any applicable legal or regulatory
                        requirement; and (c) claims arising under consumer
                        protection or similar legislation.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>6.</strong>&nbsp;In the event of any third party
                        claim that the AI Mate App or your possession and use of
                        the AI Mate App infringes such third party's intellectual
                        property rights, Apple is not responsible for the
                        investigation, defense, settlement or discharge of such
                        intellectual property infringement claim.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>7.</strong>&nbsp;You represent and warrant that
                        (a) you are not located in a country that is subject to
                        a U.S. Government embargo, or that has been designated
                        by the U.S. Government as a "terrorist supporting"
                        country; and (b) you are not listed on any U.S.
                        Government list of prohibited or restricted parties.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>8.</strong>&nbsp;Apple and its subsidiaries are
                        third party beneficiaries of this EULA and upon your
                        acceptance of the terms and conditions of this EULA,
                        Apple will have the right (and will be deemed to have
                        accepted the right) to enforce this EULA against you as
                        a third party beneficiary hereof.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>9.</strong>&nbsp;YouPro expressly authorizes use
                        of the AI Mate App by multiple users through the Family
                        Sharing or any similar functionality provided by Apple.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>Windows Phone Store:</strong> By downloading the
                        the AI Mate App from the Windows Phone Store, you
                        specifically acknowledge and agree that:
                      </p>{" "}
                      <br />
                      <p>
                        <strong>1.</strong>&nbsp;You may install and use one
                        copy of the AI Mate App on up to five (5) Windows Phone
                        enabled devices that are affiliated with the Microsoft
                        account you use to access the Windows Phone Store.
                        Beyond that, we reserve the right to apply additional
                        conditions or charge additional fees.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>2.</strong>&nbsp;You acknowledge that Microsoft
                        Corporation, your phone manufacturer and network
                        operator have no obligation whatsoever to furnish any
                        maintenance and support services with respect to the
                        the AI Mate App.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>END OF DOCUMENT.</strong>
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
      </div>
    );
  }
}

export default AITermsOfUse;
