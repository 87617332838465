import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  Link,
  NativeSelect,
  Stack,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { Add } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const LanguageSearch = () => {
  const [list, setList] = useState([]);
  const [search, setSearch] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const findContentJS = (items, searchTerm) => {
    let langs = [];
    items.forEach((item) => {
      console.log(item);
      if (item.languages.toLowerCase().includes(searchTerm.toLowerCase())) {
        langs.push(item);
      }
    });
    return langs;
  };

  const getList = () => {
    setList(findContentJS(languages, search));
  };
  useEffect(() => getList(), [search]);
  let languages = [
    {
      image: require("../assets/images/app/bulgo/bulgo-mobile-1.png").default,
      languages: "Translate every languages, scan& create QR Code",
      detail_link: "https://youpro.store/BulGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/translate-bulgo/id6443971808",
    },
    {
      image: require("../assets/images/app/catago/catago-mobile-1.png").default,
      languages: "Translate every languages, scan& create QR Code",
      detail_link: "https://youpro.store/CataGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/translate-catago/id6443965485",
    },
    {
      image: require("../assets/images/app/esgot/esgot-mobile-1.png").default,
      languages: "Translate every languages, scan& create QR Code",
      detail_link: "https://youpro.store/EsGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.learn_spanish",
      ios_link: "https://apps.apple.com/us/app/translate-esgot/id6443599827",
    },
    {
      image: require("../assets/images/app/translator/translator-mobile-1.png")
        .default,
      languages: "Translate every languages, scan& create QR Code",
      detail_link: "https://youpro.store/Translator",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.translation",
      ios_link:
        "https://apps.apple.com/us/app/translate-translator/id1639066465",
    },
    {
      image:
        require("../assets/images/app/etranslator/etranslator-mobile-1.png")
          .default,
      languages: "Translate every languages, scan& create QR Code",
      detail_link: "https://youpro.store/eTranslator",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/translate-etranslator/id1635502075",
    },

    {
      image: require("../assets/images/app/gtranslate/gtranslate-mobile-1.png")
        .default,
      languages:
        "Translate every languages, AI Chatbot: Answer all your questions ",
      detail_link: "https://youpro.store/gTranslate",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.voice_translator_translate_photo",
      ios_link:
        "https://apps.apple.com/us/app/voice-translator-translate/6447427058",
    },

    {
      image: require("../assets/images/app/qrmaster/qrmaster-mobile-1.png")
        .default,
      languages: "scan& create any QR Code, Barcode, UPC including complex QRs",
      detail_link: "https://youpro.store/QRMaster",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.qr_master",
      ios_link:
        "https://apps.apple.com/us/app/qr-code-reader-generator-free/id1643891695",
    },
    {
      image: require("../assets/images/app/tago/tago-mobile-1.png").default,
      languages: "Translate every languages to Tagalog, Filipino",
      detail_link: "https://youpro.store/TaGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/tago-translator/id1639258543",
    },

    {
      image: require("../assets/images/app/frangot/frangot-mobile-1.png")
        .default,
      languages: "Translate every languages to French",
      detail_link: "https://youpro.store/FranGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/frangot-translator-learn/id6444466846",
    },

    {
      image: require("../assets/images/app/vigot/vigot-mobile-1.png").default,
      languages: "Translate every languages to Vietnamese",
      detail_link: "https://youpro.store/ViGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/vigot-translator-learn/id6444584487",
    },

    {
      image: require("../assets/images/app/hangot/hangot-mobile-1.png").default,
      languages: "Translate every languages to Korean",
      detail_link: "https://youpro.store/HanGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/hangot-translator-learn/id6444580728",
    },
    {
      image: require("../assets/images/app/japangot/japangot-mobile-1.png")
        .default,
      languages: "Translate every languages to Japanese",
      detail_link: "https://youpro.store/JapanGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/japangot-translator-learn/id6444585315",
    },

    {
      image: require("../assets/images/app/pogot/pogot-mobile-1.png").default,
      languages: "Translate every languages to Portuguese",
      detail_link: "https://youpro.store/PoGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/pogot-translator-learn/id6444505265",
    },

    {
      image:
        require("../assets/images/app/ai-image-generator/ai-image-generator-mobile-1.png")
          .default,
      languages: "Leading AI Image Generator in the world",
      detail_link: "https://youpro.store/AI-Image-Generator",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.photo_editer_image_maker",
      ios_link:
        "https://apps.apple.com/us/app/photo-editor-image-maker/id6452629051",
    },

    {
      image: require("../assets/images/app/danishgot/danishgot-mobile-1.png")
        .default,
      languages: "Translate every languages to Danish",
      detail_link: "https://youpro.store/DanishGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/danishgot-translator-learn/id1661410138",
    },
    {
      image: require("../assets/images/app/dutchgot/dutchgot-mobile-1.png")
        .default,
      languages: "Translate every languages to Dutch",
      detail_link: "https://youpro.store/DutchGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/dutchgot-translator-learn/id1661408809",
    },
    {
      image: require("../assets/images/app/polgot/polgot-mobile-1.png").default,
      languages: "Translate every languages to Polish",
      detail_link: "https://youpro.store/PolGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/polgot-translator-learn/id1661276629",
    },
    {
      image: require("../assets/images/app/rusgot/rusgot-mobile-1.png").default,
      languages: "Translate every languages to Russian",
      detail_link: "https://youpro.store/RusGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/rusgot-translator-learn/id1661265296",
    },
    {
      image: require("../assets/images/app/ukrainegot/ukrainegot-mobile-1.png")
        .default,
      languages: "Translate every languages to Ukrainian",
      detail_link: "https://youpro.store/UkraineGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.krainegot",
      ios_link:
        "https://apps.apple.com/us/app/ukrainegot-translator-learn/id1661055341",
    },

    {
      image: require("../assets/images/app/note/note-mobile-1.png").default,
      languages: "Make Note with image, video and text!",
      detail_link: "https://youpro.store/NoteMaster",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.note_master",
      ios_link:
        "https://apps.apple.com/us/app/notes-note-master-to-do-list/id1665592021",
    },

    {
      image: require("../assets/images/app/todo/todo-mobile-1.png").default,
      languages: "Make Checklist & Set Plans for everyday",
      detail_link: "https://youpro.store/ToDoList",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.to_do_list",
      ios_link:
        "https://apps.apple.com/us/app/to-do-list-checklist/id1672512256",
    },
    {
      image: require("../assets/images/app/voicenote/voicenote-mobile-1.png")
        .default,
      languages: "Modern Voice Note & Voice Memos",
      detail_link: "https://youpro.store/VoiceNote",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.voice_note",
      ios_link: "https://apps.apple.com/us/app/voice-note-notes//id6446006371",
    },

    {
      image: require("../assets/images/app/aimate/aimate-mobile-1.png").default,
      languages: "AI Chatbot and Image Generator",
      detail_link: "https://youpro.store/AIMate",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.chatgpt_chat_gpt_chatbot",
      ios_link:
        "https://apps.apple.com/us/app/gptchat-chatgpt-ai-gpt-chatbot/id6447050093",
    },

    {
      image: require("../assets/images/app/diary/diary-mobile-1.png").default,
      languages: "Modern Voice Note & Voice Memos",
      detail_link: "https://youpro.store/Diary",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.journal_diary_with_lock_password",
      ios_link:
        "https://apps.apple.com/us/app/diary-with-lock-password/id6446240418",
    },

    {
      image: require("../assets/images/app/degot/degot-mobile-1.png").default,
      languages: "Translate every languages to German",
      detail_link: "https://youpro.store/DeGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/degot-translator-learn/id6444572674",
    },

    {
      image: require("../assets/images/app/italygot/italygot-mobile-1.png")
        .default,
      languages: "Translate every languages to Italian",
      detail_link: "https://youpro.store/ItalyGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/italygot-translator-learn/id6444774186",
    },
    {
      image: require("../assets/images/app/voicegot/voicegot-mobile-1.png")
        .default,
      languages: "Translate every languages to your language",
      detail_link: "https://youpro.store/VoiceGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.voicegot",
      ios_link:
        "https://apps.apple.com/us/app/voicegot-translator-learn/id6444178960",
    },
    {
      image: require("../assets/images/app/cameragot/cameragot-mobile-1.png")
        .default,
      languages: "Translate every languages to your language",
      detail_link: "https://youpro.store/CameraGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.cameragot",
      ios_link:
        "https://apps.apple.com/us/app/cameragot-translator-learn/id6444845811",
    },
    {
      image: require("../assets/images/app/thaigot/thaigot-mobile-1.png")
        .default,
      languages: "Translate every languages to Thai",
      detail_link: "https://youpro.store/ThaiGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/thaigot-translator-learn/id6444782966",
    },
    {
      image: require("../assets/images/app/turkgot/turkgot-mobile-1.png")
        .default,
      languages: "Translate every languages to Turkish",
      detail_link: "https://youpro.store/TurkGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/turkgot-translator-learn/id6444796307",
    },
    {
      image: require("../assets/images/app/tagagot/tagagot-mobile-1.png")
        .default,
      languages: "Translate every languages to Tagalog",
      detail_link: "https://youpro.store/TagaGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translatort",
      ios_link:
        "https://apps.apple.com/us/app/tagagot-translator-learn/id6444786625",
    },

    {
      image: require("../assets/images/app/thaigo/thaigo-mobile-1.png").default,
      languages: "Translate every languages to Thai",
      detail_link: "https://youpro.store/ThaiGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/thaigo-translator/id6443397879",
    },
    {
      image: require("../assets/images/app/danskgo/danskgo-mobile-1.png")
        .default,
      languages: "Translate every languages to Danish",
      detail_link: "https://youpro.store/danskgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/danskgo-translator/id1644271122",
    },
    {
      image: require("../assets/images/app/fingo/fingo-mobile-1.png").default,
      languages: "Translate every languages to Finnish",
      detail_link: "https://youpro.store/fingo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/fingo-translator/id6443436855",
    },
    {
      image: require("../assets/images/app/chigo/chigo-mobile-1.png").default,
      languages: "Translate every languages to Chinese",
      detail_link: "https://youpro.store/chigo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/chigo-translator/id1645670393",
    },
    {
      image: require("../assets/images/app/japango/japango-mobile-1.png")
        .default,
      languages: "Translate every languages to Japanese",
      detail_link: "https://youpro.store/japango",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/japango-translator/id6443433459",
    },
    {
      image: require("../assets/images/app/ukrainego/ukrainego-mobile-1.png")
        .default,
      languages: "Translate every languages to Ukrainian",
      detail_link: "https://youpro.store/ukrainego",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/ukrainego/id6443465798",
    },
    {
      image: require("../assets/images/app/czechgo/czechgo-mobile-1.png")
        .default,
      languages: "Translate every languages to Czech",
      detail_link: "https://youpro.store/czechgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/czechgo-translator/id6443472444",
    },
    {
      image: require("../assets/images/app/serbgo/serbgo-mobile-1.png").default,
      languages: "Translate every languages to Serbian",
      detail_link: "https://youpro.store/serbgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/serbgo-translator/id6443462290",
    },
    {
      image: require("../assets/images/app/romango/romango-mobile-1.png")
        .default,
      languages: "Translate every languages to Romanian",
      detail_link: "https://youpro.store/romango",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/romango-translator/id6443444869",
    },
    {
      image: require("../assets/images/app/norwaygo/norwaygo-mobile-1.png")
        .default,
      languages: "Translate every languages to Norwegian",
      detail_link: "https://youpro.store/norwaygo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link:
        "https://apps.apple.com/us/app/norwaygo-translator/id6443441125",
    },
    {
      image: require("../assets/images/app/hungo/hungo-mobile-1.png").default,
      languages: "Translate every languages to Hungarian",
      detail_link: "https://youpro.store/hungo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/hungo-translator/id6443442591",
    },
    {
      image: require("../assets/images/app/indogo/indogo-mobile-1.png").default,
      languages: "Translate every languages to Indonesian",
      detail_link: "https://youpro.store/indogo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/indogo-translator/id6443474239",
    },
    {
      image: require("../assets/images/app/swedgo/swedgo-mobile-1.png").default,
      languages: "Translate every languages to Swedish",
      detail_link: "https://youpro.store/swedgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/swedgo-translator/id6443417992",
    },
    {
      image: require("../assets/images/app/hingo/hingo-mobile-1.png").default,
      languages: "Translate every languages to Hindi",
      detail_link: "https://youpro.store/hingo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/hingo-translator/id6443433459",
    },
    {
      image: require("../assets/images/app/itago/itago-mobile-1.png").default,
      languages: "Translate every languages to italian",
      detail_link: "https://youpro.store/itaGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/itago-translator/id1639776067",
    },
    {
      image: require("../assets/images/app/hango/hango-mobile-1.png").default,
      languages: "Translate every languages to Korean or Korean to others",
      detail_link: "https://youpro.store/HanGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.hango",
      ios_link: "https://apps.apple.com/us/app/hango-translator/id1640404700",
    },
    {
      image: require("../assets/images/app/frango/frango-mobile-1.png").default,
      languages: "Translate every languages to French or French to others",
      detail_link: "https://youpro.store/FranGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/frango-translator/id1640836844",
    },
    {
      image: require("../assets/images/app/greekgo/greekgo-mobile-1.png")
        .default,
      languages: "Translate every languages to Greek or Greek to others",
      detail_link: "https://youpro.store/greekgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/greekgo-translator/id1643279525",
    },
    {
      image: require("../assets/images/app/rusgo/rusgo-mobile-1.png").default,
      languages: "Translate every languages to Russian or Russian to others",
      detail_link: "https://youpro.store/rusgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/rusgo-translator/id1643641415",
    },
    {
      image: require("../assets/images/app/turkgo/turkgo-mobile-1.png").default,
      languages: "Translate every languages to Turkish or Turkish to others",
      detail_link: "https://youpro.store/turkgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/turkgo-translator/id1643811577",
    },
    {
      image: require("../assets/images/app/dego/dego-mobile-1.png").default,
      languages: "Translate every languages to German or German to others",
      detail_link: "https://youpro.store/dego",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/dego-translator/id1641276883",
    },
    {
      image: require("../assets/images/app/portgo/portgo-mobile-1.png").default,
      languages:
        "Translate every languages to Portuguese or Portuguese to others",
      detail_link: "https://youpro.store/PortGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/portgo-translator/id1641485437",
    },
    {
      image: require("../assets/images/app/espago/espago-mobile-1.png").default,
      languages: "Translate every languages to spanish or spanish to others",
      detail_link: "https://youpro.store/EspaGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/espago-translator/id1641943408",
    },

    {
      image: require("../assets/images/app/dutgo/dutgo-mobile-1.png").default,
      languages: "Translate 45 languages to dutch or dutch to others",
      detail_link: "https://youpro.store/dutgo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/dutgo-translator/id1642131064",
    },
    {
      image: require("../assets/images/app/polgo/polgo-mobile-1.png").default,
      languages:
        "Translate 45 languages to polish or polish to other languages",
      detail_link: "https://youpro.store/PolGo",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/polgo-translator/id1642656663",
    },
    {
      image: require("../assets/images/app/vietgot/vietgot-mobile-1.png")
        .default,
      languages:
        "Translate 45 languages to vietnamese or vietnamese to other languages",
      detail_link: "https://youpro.store/VietGot",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/vietgot-translator/id1642779136",
    },
    {
      image: require("../assets/images/app/brago/brago-mobile-1.png").default,
      languages:
        "Translate every languages to Brazilian Portuguese or Brazilian Portuguese to others",
      detail_link: "https://youpro.store/brago",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.e_translator",
      ios_link: "https://apps.apple.com/us/app/brago-translator/id1641676884",
    },
    {
      image:
        require("../assets/images/app/english-to-arabic/english-to-arabic-mobile-1.png")
          .default,
      languages: "English to Arabic",
      detail_link: "https://youpro.store/english-to-arabic",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_arabic",
      ios_link:
        "https://apps.apple.com/us/app/english-to-arabic-youpro/id1631302779",
    },
    {
      image:
        require("../assets/images/app/english-to-chinese/english-to-chinese-mobile-1.png")
          .default,
      languages: "English to Chinese",
      detail_link: "https://youpro.store/english-to-chinese",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_chinese",
      ios_link:
        "https://apps.apple.com/us/app/english-to-chinese-youpro/id1628083958",
    },
    {
      image:
        require("../assets/images/app/english-to-german/english-to-german-mobile-1.png")
          .default,
      languages: "English to German",
      detail_link: "https://youpro.store/english-to-german",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_german",
      ios_link:
        "https://apps.apple.com/us/app/english-to-german-app/id1629948108",
    },
    {
      image:
        require("../assets/images/app/english-to-japanese/english-to-japanese-mobile-1.png")
          .default,
      languages: "English to Japanese",
      detail_link: "https://youpro.store/english-to-japanese",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_japanese.english_to_japanese",
      ios_link:
        "https://apps.apple.com/us/app/english-to-japanese-youpro/id1629555285",
    },
    {
      image:
        require("../assets/images/app/english-to-korean/english-to-korean-mobile-1.png")
          .default,
      languages: "English to Korean",
      detail_link: "https://youpro.store/english-to-korean",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_korean",
      ios_link:
        "https://apps.apple.com/us/app/english-to-korean-app/id1628045201",
    },
    {
      image:
        require("../assets/images/app/english-to-portuguese/english-to-portuguese-mobile-1.png")
          .default,
      languages: "English to Portuguese",
      detail_link: "https://youpro.store/english-to-portuguese",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_portuguese",
      ios_link:
        "https://apps.apple.com/us/app/english-to-portuguese-app/id1631667250",
    },
    {
      image:
        require("../assets/images/app/english-to-spanish/english-to-spanish-mobile-1.png")
          .default,
      languages: "English to Spanish",
      detail_link: "https://youpro.store/english-to-spanish",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_spanish",
      ios_link:
        "https://apps.apple.com/tt/app/english-to-spanish-youpro/id1628975231",
    },
    {
      image:
        require("../assets/images/app/english-to-tagalog/english-to-tagalog-mobile-1.png")
          .default,
      languages: "English to Tagalog",
      detail_link: "https://youpro.store/english-to-tagalog",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_tagalog",
      ios_link:
        "https://apps.apple.com/us/app/english-to-tagalog-youpro/id1626456370",
    },
    {
      image:
        require("../assets/images/app/english-to-russian/english-to-russian-mobile-1.png")
          .default,
      languages: "English to Russian",
      detail_link: "https://youpro.store/english-to-russian",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_russian",
      ios_link:
        "https://apps.apple.com/us/app/english-to-russian-app/id1632383731",
    },
    {
      image:
        require("../assets/images/app/english-to-italian/english-to-italian-mobile-1.png")
          .default,
      languages: "English to Italian",
      detail_link: "https://youpro.store/english-to-italian",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.italian_to_english",
      ios_link:
        "https://apps.apple.com/us/app/italian-to-english-app/id1632578565",
    },
    {
      image:
        require("../assets/images/app/english-to-french/english-to-french-mobile-1.png")
          .default,
      languages: "English to French",
      detail_link: "https://youpro.store/english-to-french",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_french",
      ios_link:
        "https://apps.apple.com/us/app/english-to-french-app/id1633023836",
    },
    {
      image:
        require("../assets/images/app/english-to-hindi/english-to-hindi-mobile-1.png")
          .default,
      languages: "English to Hindi",
      detail_link: "https://youpro.store/english-to-hindi",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_hindi",
      ios_link:
        "https://apps.apple.com/us/app/english-to-hindi-app/id6443432594",
    },
    {
      image:
        require("../assets/images/app/english-to-vietnamese/english-to-vietnamese-mobile-1.png")
          .default,
      languages: "English to Vietnamese",
      detail_link: "https://youpro.store/english-to-vietnamese",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_vietnamese",
      ios_link:
        "https://apps.apple.com/us/app/english-to-vietnamese-app/id1633581067",
    },
    {
      image:
        require("../assets/images/app/english-to-polish/english-to-polish-mobile-1.png")
          .default,
      languages: "English to Polish",
      detail_link: "https://youpro.store/english-to-polish",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_polish",
      ios_link:
        "https://apps.apple.com/us/app/english-to-polish-app/id1633772123",
    },
    {
      image:
        require("../assets/images/app/english-to-greek/english-to-greek-mobile-1.png")
          .default,
      languages: "English to Greek",
      detail_link: "https://youpro.store/english-to-greek",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_greek",
      ios_link:
        "https://apps.apple.com/us/app/english-to-greek-app/id1633825788",
    },
    {
      image:
        require("../assets/images/app/english-to-turkish/english-to-turkish-mobile-1.png")
          .default,
      languages: "English to Turkish",
      detail_link: "https://youpro.store/english-to-turkish",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_turkish",
      ios_link:
        "https://apps.apple.com/us/app/english-to-turkish-app/id1633875754",
    },

    {
      image:
        require("../assets/images/app/english-to-dutch/english-to-dutch-mobile-1.png")
          .default,
      languages: "English to Dutch",
      detail_link: "https://youpro.store/english-to-dutch",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_dutch",
      ios_link:
        "https://apps.apple.com/us/app/english-to-dutch-app/id1633956528",
    },
    {
      image:
        require("../assets/images/app/english-to-swedish/english-to-swedish-mobile-1.png")
          .default,
      languages: "English to Swedish",
      detail_link: "https://youpro.store/english-to-swedish",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_swedish",
      ios_link:
        "https://apps.apple.com/us/app/english-to-swedish-app/id1634171573",
    },
    {
      image:
        require("../assets/images/app/english-to-danish/english-to-danish-mobile-1.png")
          .default,
      languages: "English to Danish",
      detail_link: "https://youpro.store/english-to-danish",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_danish",
      ios_link:
        "https://apps.apple.com/us/app/english-to-danish-app/id1634374122",
    },
    {
      image:
        require("../assets/images/app/english-to-thai/english-to-thai-mobile-1.png")
          .default,
      languages: "English to Thai",
      detail_link: "https://youpro.store/english-to-thai",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_thai",
      ios_link:
        "https://apps.apple.com/us/app/english-to-thai-app/id1634763742",
    },
    {
      image:
        require("../assets/images/app/english-to-finnish/english-to-finnish-mobile-1.png")
          .default,
      languages: "English to Finnish",
      detail_link: "https://youpro.store/english-to-finnish",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_finnish",
      ios_link:
        "https://apps.apple.com/us/app/english-to-finnish-app/id1634932996",
    },
    {
      image:
        require("../assets/images/app/english-to-romanian/english-to-romanian-mobile-1.png")
          .default,
      languages: "English to Romanian",
      detail_link: "https://youpro.store/english-to-romanian",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_romanian",
      ios_link:
        "https://apps.apple.com/us/app/english-to-romanian-traducerea/id1635313828",
    },
    {
      image:
        require("../assets/images/app/english-to-norwegian/english-to-norwegian-mobile-1.png")
          .default,
      languages: "English to Norwegian",
      detail_link: "https://youpro.store/english-to-norwegian",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_norwegian",
      ios_link:
        "https://apps.apple.com/us/app/english-norwegian-translator/id1635938832",
    },
    {
      image:
        require("../assets/images/app/english-to-hungarian/english-to-hungarian-mobile-1.png")
          .default,
      languages: "English to Hungarian",
      detail_link: "https://youpro.store/english-to-hungarian",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_hungarian",
      ios_link:
        "https://apps.apple.com/us/app/english-to-hungarian-app/id1636096661",
    },
    {
      image:
        require("../assets/images/app/english-to-latvian/english-to-latvian-mobile-1.png")
          .default,
      languages: "English to Latvian",
      detail_link: "https://youpro.store/english-to-latvian",
      android_link:
        "https://play.google.com/store/apps/details?id=com.amazinpro.english_to_latvian",
      ios_link:
        "https://apps.apple.com/us/app/english-to-latvian-app/id1636612907",
    },
  ];

  return (
    <>
      <Box pb={3}>
        <h3 align="center">Find your language</h3>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={false}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              height: 60,
            }}
          >
            <SearchIcon />
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Type any language"
              fullWidth={true}
              inputProps={{ "aria-label": "search your language" }}
              onChange={handleSearch}
            />
          </Paper>
          <Box sx={{ pt: 3 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">No.</StyledTableCell>
                    <StyledTableCell align="center">Icon</StyledTableCell>
                    <StyledTableCell align="center">Languages</StyledTableCell>
                    <StyledTableCell align="center">Download</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.length != 0 ? (
                    list?.map((lang, index) => (
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(lang.detail_link);
                            }}
                          >
                            <img
                              className="image-1"
                              height="100"
                              weight="100"
                              src={lang.image}
                              alt="App Landing"
                            />
                          </button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <a
                            href={lang.detail_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lang.languages}
                          </a>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              window.location.href = lang.android_link;
                            }}
                          >
                            Google Play
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() => {
                              window.location.href = lang.ios_link;
                            }}
                          >
                            App Store
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        No Results
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ pt: 3 }}>
              <Pagination
                count={
                  list?.total / list?.per_page
                    ? Math.ceil(list?.total / list?.per_page)
                    : 1
                }
                page={list?.current_page ? list?.current_page : 0}
                variant="outlined"
                color="primary"
                onChange={(event, pageNumber) => {
                  setCurrentPage(pageNumber);
                  //getUser(pageNumber, 10);
                }}
                shape="rounded"
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LanguageSearch;
