import Home from "./pages/Home";
import EnglishSpanish from "./pages/EnglishSpanish";
import EnglishArabic from "./pages/EnglishArabic";
import EnglishChinese from "./pages/EnglishChinese";
import EnglishGerman from "./pages/EnglishGerman";
import EnglishJapanese from "./pages/EnglishJapanese";
import EnglishKorean from "./pages/EnglishKorean";
import EnglishTagalog from "./pages/EnglishTagalog";
import EnglishRussian from "./pages/EnglishRussian";
import EnglishPortuguese from "./pages/EnglishPortuguese";
import EnglishItalian from "./pages/EnglishItalian";
import EnglishFrench from "./pages/EnglishFrench";
import eTranslator from "./pages/eTranslator";
import PortGo from "./pages/PortGo";
import PolGo from "./pages/PolGo";
import BulGo from "./pages/BulGo";
import VoiceNote from "./pages/VoiceNote";
import CataGo from "./pages/CataGo";
import EsGot from "./pages/EsGot";
import Translator from "./pages/Translator";
import TaGo from "./pages/TaGo";
import NoteMaster from "./pages/Note";
import ToDoList from "./pages/ToDo";
import ThaiGo from "./pages/ThaiGo";
import RusGo from "./pages/RusGo";
import TurkGo from "./pages/TurkGo";
import GreekGo from "./pages/GreekGo";
import DanishGot from "./pages/DanishGot";
import DutchGot from "./pages/DutchGot";
import PolGot from "./pages/PolGot";
import RusGot from "./pages/RusGot";
import UkraineGot from "./pages/UkraineGot";
import VietGot from "./pages/VietGot";
import DutGo from "./pages/DutGo";
import BraGo from "./pages/BraGo";
import DeGo from "./pages/DeGo";
import DanskGo from "./pages/DanskGo";
import CzechGo from "./pages/CzechGo";
import FinGo from "./pages/FinGo";
import RomanGo from "./pages/RomanGo";
import NorwayGo from "./pages/NorwayGo";
import HunGo from "./pages/HunGo";
import UkraineGo from "./pages/UkraineGo";
import JapanGo from "./pages/JapanGo";
import SerbGo from "./pages/SerbGo";
import HinGo from "./pages/HinGo";
import ChiGo from "./pages/ChiGo";
import IndoGo from "./pages/IndoGo";
import SwedGo from "./pages/SwedGo";
import EspaGo from "./pages/EspaGo";
import HanGo from "./pages/HanGo";
import gTranslate from "./pages/gTranslate";
import FranGo from "./pages/FranGo";
import QRMaster from "./pages/QRMaster";
import itaGo from "./pages/itaGo";
import FranGot from "./pages/FranGot";
import ViGot from "./pages/ViGot";
import HanGot from "./pages/HanGot";
import JapanGot from "./pages/JapanGot";
import PoGot from "./pages/PoGot";
import DeGot from "./pages/DeGot";
import ItalyGot from "./pages/ItalyGot";
import VoiceGot from "./pages/VoiceGot";
import AIMate from "./pages/AIMate";
import Diary from "./pages/Diary";
import CameraGot from "./pages/CameraGot";
import AIImageGenerator from "./pages/AIImageGenerator";
import ThaiGot from "./pages/ThaiGot";
import TurkGot from "./pages/TurkGot";
import TagaGot from "./pages/TagaGot";
import EnglishRomanian from "./pages/EnglishRomanian";
import EnglishThai from "./pages/EnglishThai";
import EnglishVietnamese from "./pages/EnglishVietnamese";
import EnglishHindi from "./pages/EnglishHindi";
import EnglishPolish from "./pages/EnglishPolish";
import EnglishGreek from "./pages/EnglishGreek";
import EnglishDutch from "./pages/EnglishDutch";
import EnglishFinnish from "./pages/EnglishFinnish";
import EnglishDanish from "./pages/EnglishDanish";
import EnglishSwedish from "./pages/EnglishSwedish";
import EnglishTurkish from "./pages/EnglishTurkish";
import EnglishLatvian from "./pages/EnglishLatvian";
import EnglishHungarian from "./pages/EnglishHungarian";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AIPrivacyPolicy from "./pages/AIPrivacyPolicy";
import AITermsOfUse from "./pages/AITermsOfUse";
import PhotoStudioPrivacyPolicy from "./pages/PhotoStudioPrivacyPolicy";
import PhotoStudioTermsOfUse from "./pages/PhotoStudioTermsOfUse";
import gTranslatePrivacyPolicy from "./pages/gTranslatePrivacyPolicy";
import gTranslateTermsOfUse from "./pages/gTranslateTermsOfUse";
import TermsOfUse from "./pages/TermsOfUse";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import EnglishNorwegian from "./pages/EnglishNorwegian";

const App = () => {
  /*
  const firebaseConfig = {
    apiKey: "AIzaSyA1ZrEHrAWGZ-skxUL2keNzF4hUX4gzp7U",
    authDomain: "youproweb.firebaseapp.com",
    projectId: "youproweb",
    storageBucket: "youproweb.appspot.com",
    messagingSenderId: "564880397568",
    appId: "1:564880397568:web:d3f0b0555d7ab421f6cfe5",
    measurementId: "G-0R3S182FQL",
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  */

  const TRACKING_ID = "UA-231896662-2"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-spanish`}
            component={EnglishSpanish}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-arabic`}
            component={EnglishArabic}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-chinese`}
            component={EnglishChinese}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-german`}
            component={EnglishGerman}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-korean`}
            component={EnglishKorean}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-japanese`}
            component={EnglishJapanese}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/todolist`}
            component={ToDoList}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/diary`}
            component={Diary}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/voicenote`}
            component={VoiceNote}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai-image-generator`}
            component={AIImageGenerator}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/aimate`}
            component={AIMate}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/gtranslate`}
            component={gTranslate}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-tagalog`}
            component={EnglishTagalog}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-latvian`}
            component={EnglishLatvian}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-russian`}
            component={EnglishRussian}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-italian`}
            component={EnglishItalian}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-portuguese`}
            component={EnglishPortuguese}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-french`}
            component={EnglishFrench}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-thai`}
            component={EnglishThai}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-finnish`}
            component={EnglishFinnish}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-greek`}
            component={EnglishGreek}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-turkish`}
            component={EnglishTurkish}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-hindi`}
            component={EnglishHindi}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-vietnamese`}
            component={EnglishVietnamese}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-polish`}
            component={EnglishPolish}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-dutch`}
            component={EnglishDutch}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-swedish`}
            component={EnglishSwedish}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-danish`}
            component={EnglishDanish}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-norwegian`}
            component={EnglishNorwegian}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-romanian`}
            component={EnglishRomanian}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/english-to-hungarian`}
            component={EnglishHungarian}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/BulGo`}
            component={BulGo}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/FranGot`}
              component={FranGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/ViGot`}
              component={ViGot}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/JapanGot`}
            component={JapanGot}
          />


<Route
            exact
            path={`${process.env.PUBLIC_URL}/DanishGot`}
            component={DanishGot}
           />
		   <Route
            exact
            path={`${process.env.PUBLIC_URL}/DutchGot`}
            component={DutchGot}
           />
		   <Route
            exact
            path={`${process.env.PUBLIC_URL}/PolGot`}
            component={PolGot}
           />
		   <Route
            exact
            path={`${process.env.PUBLIC_URL}/RusGot`}
            component={RusGot}
           />
		   <Route
            exact
            path={`${process.env.PUBLIC_URL}/UkraineGot`}
            component={UkraineGot}
           />

          <Route
              exact
              path={`${process.env.PUBLIC_URL}/HanGot`}
              component={HanGot}
          />
         
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/PoGot`}
              component={PoGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/DeGot`}
              component={DeGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/ItalyGot`}
              component={ItalyGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/VoiceGot`}
              component={VoiceGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/CameraGot`}
              component={CameraGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/ThaiGot`}
              component={ThaiGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/TurkGot`}
              component={TurkGot}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/TagaGot`}
              component={TagaGot}
          />
		      <Route
            exact
            path={`${process.env.PUBLIC_URL}/CataGo`}
            component={CataGo}
          />
		      <Route
            exact
            path={`${process.env.PUBLIC_URL}/EsGot`}
            component={EsGot}
          />
		      <Route
            exact
            path={`${process.env.PUBLIC_URL}/Translator`}
            component={Translator}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/eTranslator`}
            component={eTranslator}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/TaGo`}
            component={TaGo}
          />
               <Route
            exact
            path={`${process.env.PUBLIC_URL}/TurkGo`}
            component={TurkGo}
          />
              <Route
            exact
            path={`${process.env.PUBLIC_URL}/QRMaster`}
            component={QRMaster}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/EspaGo`}
            component={EspaGo}
          />
            <Route
            exact
            path={`${process.env.PUBLIC_URL}/PortGo`}
            component={PortGo}
          />
            <Route
            exact
            path={`${process.env.PUBLIC_URL}/CzechGo`}
            component={CzechGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/DanskGo`}
            component={DanskGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/FinGo`}
            component={FinGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/RomanGo`}
            component={RomanGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/NorwayGo`}
            component={NorwayGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/HunGo`}
            component={HunGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/NoteMaster`}
            component={NoteMaster}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/UkraineGo`}
            component={UkraineGo}
          />
          <Route
           exact
            path={`${process.env.PUBLIC_URL}/JapanGo`}
            component={JapanGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/SerbGo`}
            component={SerbGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/HinGo`}
            component={HinGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ChiGo`}
            component={ChiGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IndoGo`}
            component={IndoGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/SwedGo`}
            component={SwedGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/PolGo`}
            component={PolGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/HanGo`}
            component={HanGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/DeGo`}
            component={DeGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ThaiGo`}
            component={ThaiGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/RusGo`}
            component={RusGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/VietGot`}
            component={VietGot}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/DutGo`}
            component={DutGo}
          
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/GreekGo`}
            component={GreekGo}
          
          />
          
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/BraGo`}
            component={BraGo}
          />
                   <Route
            exact
            path={`${process.env.PUBLIC_URL}/FranGo`}
            component={FranGo}
          />
                  <Route
            exact
            path={`${process.env.PUBLIC_URL}/itaGo`}
            component={itaGo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/privacy-policy`}
            component={PrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai-mate-privacy-policy`}
            component={AIPrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai-mate-terms-of-use`}
            component={AITermsOfUse}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/photo-studio-privacy-policy`}
            component={PhotoStudioPrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/photo-studio-terms-of-use`}
            component={PhotoStudioTermsOfUse}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gtranslate-privacy-policy`}
            component={gTranslatePrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gtranslate-terms-of-use`}
            component={gTranslateTermsOfUse}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/terms-of-use`}
            component={TermsOfUse}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
