import React, { Component, Fragment } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

/*https://designcode.io/react-hooks-handbook-seo-and-metadata*/

class AIImageGenerator extends Component {
  render() {
    let data = [
      {
        icon: "zmdi zmdi-settings",
        title: "AI Image Generator",
        desc: "The leading AI Image Generator in the world.",
      },

      {
        icon: "zmdi zmdi-favorite",
        title: "User Friendly",
        desc: "The application is designed in multiple languages, very convenient and user-friendly to use.",
      },

      {
        icon: "zmdi zmdi-time",
        title: "24/7 Support",
        desc: "The application is created by a dedicated team, with meticulous testing and optimization. Any problems are supported immediately and are happy to help anytime.",
      },
    ];
    let DataList = data.map((val, i) => {
      return (
        <div className="col-lg-4 service-column" key={i}>
          <div className="single-service text-center">
            <div className="service-icon">
              <i className={`${val.icon}`}></i>
            </div>
            <h4 className="title">{val.title}</h4>
            <p className="desc">{val.desc}</p>
          </div>
        </div>
      );
    });
    let dataFeature = [
      {
        icon: "zmdi zmdi-collection-text",
        title: "Words >> Photo",
        desc: "Write down detailed information about the photo you want to create",
      },

      {
        icon: "zmdi zmdi-hearing",
        title: "Photo >> Art",
        desc: "Turn simple image into works of art",
      },

      {
        icon: "zmdi zmdi-image",
        title: "Multiple art styles",
        desc: "The Power of Artificial Intelligence (AI) with many styles",
      },

      {
        icon: "zmdi zmdi-language-html5",
        title: "Multi language",
        desc: "The application integrates multi-language , very easy to use.",
      },

      {
        icon: "zmdi zmdi-sun",
        title: "Light and Dark Mode",
        desc: "The application has two light and dark modes, depending on the preferences of user.",
      },
    ];

    let DataListFeature = dataFeature.map((val, i) => {
      return (
        /* Start Single Feature */
        <div className="feature" key={i}>
          <div className="feature-icon">
            <i className={`${val.icon}`} />
          </div>
          <div className="content">
            <h4 className="title">{val.title}</h4>
            <p className="desc">{val.desc}</p>
          </div>
        </div>
        /* End Single Feature */
      );
    });

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <Fragment>
        {/* Helmet - meta, description */}
        <HelmetProvider>
          <Helmet>
            <title>AI Image Generator - The leading AI Image Generator in the world.</title>
            <meta
              name="description"
              content="Leading AI Image Generator in the world - Generate amazing photos with artificial intelligence technology like you've never seen - a useful tool that is essential in your life."
            />
          </Helmet>
        </HelmetProvider>
        {/* Header Navbar */}
        <div className="app-header header--transparent sticker" id="main-menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-5 col-5">
                <div className="logo">
                  <a href={`${process.env.PUBLIC_URL}/`}>
                    <img
                      className="logo-1"
                      src={require("../assets/images/logo/logo.png").default}
                      alt="AI Image Generator logo 1"
                    />
                    <img
                      className="logo-2"
                      src={require("../assets/images/logo/logo-2.png").default}
                      alt="AI Image Generator logo 2"
                    />
                    <img
                      className="logo-3"
                      src={require("../assets/images/logo/logo-3.png").default}
                      alt="AI Image Generator logo 3"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-8 d-none d-lg-block">
                <div className="mainmenu-wrapper">
                  <nav>
                    <ul className="main-menu">
                      <li className="active">
                        <a href="/#home">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#features">Features</a>
                      </li>
                      {/* <li><a href="#reviews">Reviews</a></li> */}
                      <li>
                        <a href="#screenshots">Screenshots</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms-of-use">Terms Of Use</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-sm-7 col-7 d-block d-lg-none">
                <div className="mobile-menu">
                  <nav>
                    <ul>
                      <li className="active">
                        <a href="#home">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#features">Features</a>
                      </li>
                      {/* <li><a href="#reviews">Reviews</a></li> */}
                      <li>
                        <a href="#screenshots">Screenshots</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms-of-use">Terms Of Use</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Slider */}
        <div
          className={`slider-area bg-color bg-shape horizontal`}
          id="home"
          style={{ background: "#2d3e50" }}
        >
          <div className="container h-100">
            <div className="row">
              <div className="col-lg-7 h-100">
                <div className="banner-text">
                  <div className="banner-table-cell">
                    <h1>
                      AI Image Generator <br />{" "}
                      <span className="theme-color">
                        The leading AI Image Generator in the world.
                        <br />{" "}
                      </span>{" "}
                    </h1>
                    <p>
                    Leading AI Image Generator in the world - 
                    Generate amazing photos with artificial intelligence technology like you've never seen - a useful tool 
                    that is essential in your life.
                    </p>
                    {/*
                  <div className="banner-buttons">
                    <button
                      type="button"
                      className="button-default button-olive"
                    >
                      Download
                    </button>
                    <a
                      className="button-default button-white"
                      href="/"
                      role="button"
                    >
                      Learn more
                    </a>
                  </div>*/}
                    <div className="banner-buttons">
                      <div className="download-buttons pb--50">
                        <button
                          type="button"
                          className="download-btn"
                          onClick={(e) => {
                            //e.preventDefault();
                            window.open(
                              "https://apps.apple.com/us/app/photo-editor-image-maker/id6452629051"
                            );
                          }}
                        >
                          <i className="zmdi zmdi-apple"></i>
                          <span>
                            Download on the
                            <span className="large-text">App Store</span>{" "}
                          </span>
                        </button>
                        <button
                          type="submit"
                          className="download-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              "https://play.google.com/store/apps/details?id=com.amazinpro.photo_editer_image_maker"
                            );
                          }}
                        >
                          <i className="zmdi zmdi-google-play"></i>
                          <span>
                            Download on the
                            <span className="large-text">Play Store</span>{" "}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                            <div className="banner-apps">
                                
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-apple"></i>
                                        <h4>ios</h4>
                                        <h3>102K</h3>
                                    </div>
                                </div>

                               
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-cloud-download"></i>
                                        <h4>Download</h4>
                                        <h3>102K</h3>
                                    </div>
                                </div>

                               
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-android"/>
                                        <h4>Android</h4>
                                        <h3>102K</h3>
                                    </div>
                                </div>
                            </div>
        */}
              </div>
              <div className="col-lg-5">
                <div className="banner-product-image text-right">
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-logo.png")
                        .default
                    }
                    alt="AI Image Generator logo 1"
                  />
                  <img
                    className="image-2"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-logo.png")
                        .default
                    }
                    alt="AI Image Generator logo 2"
                  />
                  <img
                    className="image-3"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-logo.png")
                        .default
                    }
                    alt="AI Image Generator logo 3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Screenshot */}
        <div className="screenshot-area pt--20 pb--30" id="screenshots">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--20">
                  <h2>
                    APP <span className="theme-color">SCREENSHOTS</span>
                  </h2>
                  <img
                    className="image-1"
                    src={require("../assets/images/app/title-icon.png").default}
                    alt="AI Image Generator screenshot icon 1"
                  />
                  <img
                    className="image-2"
                    src={
                      require("../assets/images/app/title-icon-2.png").default
                    }
                    alt="AI Image Generator screenshot icon 2"
                  />
                  <img
                    className="image-3"
                    src={
                      require("../assets/images/app/title-icon-3.png").default
                    }
                    alt="AI Image Generator screenshot icon 3"
                  />
                </div>
              </div>
              <Carousel responsive={responsive}>
                <div>
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-screenshot-01.png")
                        .default
                    }
                    alt="AI Image Generator screenshot 1"
                  />
                </div>
                <div>
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-screenshot-03.png")
                        .default
                    }
                    alt="AI Image Generator screenshot 2"
                  />
                </div>
                <div>
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-screenshot-02.png")
                        .default
                    }
                    alt="AI Image Generator screenshot 3"
                  />
                </div>
                <div>
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-screenshot-04.png")
                        .default
                    }
                    alt="AI Image Generator screenshot 4"
                  />
                </div>
                <div>
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-screenshot-05.png")
                        .default
                    }
                    alt="AI Image Generator screenshot 5"
                  />
                </div>
                <div>
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-screenshot-06.png")
                        .default
                    }
                    alt="AI Image Generator screenshot 6"
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>

        {/* About */}
        <div className={`app-about vertical-about`} id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--50">
                  <h2>
                    ABOUT <span className="theme-color">YouPro</span>
                  </h2>
                  <img
                    className="image-1"
                    src={require("../assets/images/app/title-icon.png").default}
                    alt="AI Image Generator logo 1"
                  />
                  <img
                    className="image-2"
                    src={
                      require("../assets/images/app/title-icon-2.png").default
                    }
                    alt="AI Image Generator logo 2"
                  />
                  <img
                    className="image-3"
                    src={
                      require("../assets/images/app/title-icon-3.png").default
                    }
                    alt="AI Image Generator logo 3"
                  />
                  <p>
                    YouPro is a popular language translation brand that is used
                    globally in more than a hundred different languages,
                    including mobile applications that support voice, audio, and
                    image translation around the world. Using YouPro you will
                    experience convenience and precision with modern technology.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5 offset-lg-1 mt--40">
                <div className="about-thumbnail mr--35">
                  <img
                    className="image-1"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-mobile-1.png")
                        .default
                    }
                    alt="AI Image Generator mobile 1"
                  />
                  <img
                    className="image-2"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-mobile-2.png")
                        .default
                    }
                    alt="AI Image Generator mobile 2"
                  />
                  <img
                    className="image-3"
                    src={
                      require("../assets/images/app/ai-image-generator/ai-image-generator-mobile-3.png")
                        .default
                    }
                    alt="AI Image Generator mobile 3"
                  />
                </div>
              </div>
              <div className="col-lg-6 mt--40">
                <div className="about-content">
                  <h2 className="title">
                    THE LEADING{" "}
                    <span className="theme-color">
                      AI IMAGE GENERATOR IN THE WORLD.
                    </span>
                  </h2>
                  <p>
                    The leading AI Image Generator in the world.{" "}
                  </p>
                  <p>
                  Leading AI Image Generator in the world - Generate amazing photos with artificial intelligence technology like you've never seen - a useful tool that is essential in your life..
                  </p>
                  <p>Download and install now !</p>
                  <div className="about-buttons">
                    <button
                      type="button"
                      className="button-default button-olive button-border"
                      onClick={(e) => {
                        //e.preventDefault();
                        window.open(
                          "https://apps.apple.com/us/app/photo-editor-image-maker/id6452629051"
                        );
                      }}
                    >
                      App Store
                    </button>
                    <a
                      className="button-default button-border"
                      href="https://play.google.com/store/apps/details?id=com.amazinpro.photo_editer_image_maker"
                      role="button"
                    >
                      Google Play
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Service */}
        <div>
          {/* Start Service Area */}
          <div className={`service-area vertical-service`}>
            <div className="container">
              <div className="row">{DataList}</div>
            </div>
          </div>
          {/* End Service Area */}
        </div>

        {/*Feature */}
        <div
          className={`feature-area feature-bg-image-en-ai-image-generator pb--50 ${this.props.horizontalfeature}`}
          id="features"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--40">
                  <h2>
                    AWESOME <span className="theme-color">FEATURES</span>
                  </h2>
                  <img
                    className="image-1"
                    src={require("../assets/images/app/title-icon.png").default}
                    alt="AI Image Generator icon 1"
                  />
                  <img
                    className="image-2"
                    src={
                      require("../assets/images/app/title-icon-2.png").default
                    }
                    alt="AI Image Generator icon 2"
                  />
                  <img
                    className="image-3"
                    src={
                      require("../assets/images/app/title-icon-3.png").default
                    }
                    alt="AI Image Generator icon 3"
                  />
                  <p>
                    This great application supports users to generate amazing photos with artificial intelligence technology like you've never seen - a useful tool that is essential in your life.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--30">
              <div className="col-lg-7 offset-lg-5">
                <div className="feature-list">{DataListFeature}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Download */}

        <div className={`download-area`}>
          <div className="bg-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--55 pt--90">
                  <h2 className="text-white">
                    DOWNLOAD{" "}
                    <span className="theme-color">
                      AI Image Generator{" "}
                    </span>
                  </h2>
                  <img
                    src={
                      require("../assets/images/app/title-icon-4.png").default
                    }
                    alt="AI Image Generator icon 4"
                  />
                  <p className="text-white">
                    Download and install the application to experience. Hope
                    this application will always accompany you.
                  </p>
                </div>
              </div>
            </div>
            <div className="banner-buttons">
              <div className="download-buttons pb--50">
                <button
                  type="button"
                  className="download-btn"
                  onClick={(e) => {
                    window.open(
                      "https://apps.apple.com/us/app/photo-editor-image-maker/id6452629051"
                    );
                  }}
                >
                  <i className="zmdi zmdi-apple"></i>
                  <span>
                    Download on the
                    <span className="large-text">App Store</span>{" "}
                  </span>
                </button>
                <button
                  type="button"
                  className="download-btn"
                  onClick={(e) => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.amazinpro.photo_editer_image_maker"
                    );
                  }}
                >
                  <i className="zmdi zmdi-google-play"></i>
                  <span>
                    Download on the
                    <span className="large-text">Play Store</span>{" "}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing */}
        <div
          className={`pricing-table-area pt--40 pt_sm--100 vertical-pricing`}
          id="pricing"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--40">
                  <h2>
                    SUBSCRIPTION<span className="theme-color"> PLAN</span>
                  </h2>
                  <img
                    className="image-1"
                    src={require("../assets/images/app/title-icon.png").default}
                    alt="AI Image Generator icon 5"
                  />
                  <img
                    className="image-2"
                    src={
                      require("../assets/images/app/title-icon-2.png").default
                    }
                    alt="AI Image Generator icon 6"
                  />
                  <img
                    className="image-3"
                    src={
                      require("../assets/images/app/title-icon-3.png").default
                    }
                    alt="AI Image  Generator icon 7"
                  />
                  <p>
                    The application has many subscription modes you can choose,
                    weekly, monthly and yearly subscriptions.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--30">
              {/* Start Single Pricing */}
              <div className="col-lg-4 col-md-6 col-12 pricing-column mt--40">
                <div className="single-price-package">
                  <div className="price-title">
                    <h3>Weekly Premium</h3>
                    <div className="price">
                      <h4>
                        <span className="text-top">$</span>
                        <span className="text-large">6,99</span>
                      </h4>
                      <p>
                        <span className="text-bottom">/week</span>
                      </p>
                    </div>
                  </div>
                  <div className="price-list">
                    <ul>
                      <li>No Ads</li>
                      <li>Image Translation Available</li>
                      <li>Unlimited voice translation</li>
                      <li>Unlimited text translation</li>
                      <li>Auto-renew after 7 days</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Pricing */}

              {/* Start Single Pricing */}
              <div className="col-lg-4 col-md-6 col-12 pricing-column mt--40">
                <div className="single-price-package list-large">
                  <div className="price-title">
                    <h3>Monthly Premium</h3>
                    <div className="price">
                      <h4>
                        <span className="text-top">$</span>
                        <span className="text-large">17,99</span>
                      </h4>
                      <p>
                        <span className="text-bottom">/month</span>
                      </p>
                    </div>
                  </div>
                  <div className="price-list">
                    <ul>
                      <li>No Ads</li>
                      <li>Image Translation Available</li>
                      <li>Unlimited voice translation</li>
                      <li>Unlimited text translation</li>
                      <li>Auto-renew after 30 days</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Pricing */}

              {/* Start Single Pricing */}
              <div className="col-lg-4 col-md-6 col-12 pricing-column mt--40">
                <div className="single-price-package">
                  <div className="price-title">
                    <h3>Yearly Premium</h3>
                    <div className="price">
                      <h4>
                        <span className="text-top">$</span>
                        <span className="text-large">119,99</span>
                      </h4>
                      <p>
                        <span className="text-bottom">/year</span>
                      </p>
                    </div>
                  </div>
                  <div className="price-list">
                    <ul>
                      <li>No Ads</li>
                      <li>Image Translation Available</li>
                      <li>Unlimited voice translation</li>
                      <li>Unlimited text translation</li>
                      <li>Auto-renew after 1 year</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Pricing */}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div>
          <div className={`footer-area vertical-footer`} id="support">
            <div className="footer-bg" />
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                  <div className="newsletter text-center">
                    <h3 className="text-white title">CONTACT</h3>
                    <p className="text-white">amazinproservice@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
                  <div className="footer-links text-center">
                    <a href="https://www.facebook.com/groups/4998791663580109">
                      <i className="zmdi zmdi-facebook"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UC4ObVZt-MsVlTOn8eci9zNg">
                      <i className="zmdi zmdi-youtube"></i>
                    </a>
                  </div>
                  <div className="footer-text text-center">
                    <span>Copyright © {new Date().getFullYear()} YouPro</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AIImageGenerator;
