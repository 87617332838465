import React, { Component } from "react";

class PhotoStudioPrivacyPolicy extends Component {
  render() {
    return (
      <div>
        {/* Header Navbar */}
        <div className="app-header header--transparent sticker" id="main-menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-5 col-5">
                <div className="logo">
                  <a href={`${process.env.PUBLIC_URL}/`}>
                    <img
                      className="logo-1"
                      src={require("../assets/images/logo/logo.png").default}
                      alt="app landing"
                    />
                    <img
                      className="logo-2"
                      src={require("../assets/images/logo/logo-2.png").default}
                      alt="app landing"
                    />
                    <img
                      className="logo-3"
                      src={require("../assets/images/logo/logo-3.png").default}
                      alt="app landing"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-8 d-none d-lg-block">
                <div className="mainmenu-wrapper">
                  <nav>
                    <ul className="main-menu">
                    <li className="active">
                        <a href="/photo-studio-privacy-policy">Photo Studio's Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/photo-studio-terms-of-use">Photo Studio's Terms Of Use</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-sm-7 col-7 d-block d-lg-none">
                <div className="mobile-menu">
                  <nav>
                    <ul>
                     
                    <li className="active">
                        <a href="/photo-studio-privacy-policy">Photo Studio's Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/photo-studio-terms-of-use">Photo Studio's Terms Of Use</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* breadcrumb Area */}
        <div className="breadcrumb-area ptb--30 " data-black-overlay="9">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h2 className="title">Photo Studio's Privacy Policy</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details">
                    <div>
                      <br />
                      <p>
                        The following Data Protection Policy and Privacy Policy
                        (the “Privacy Policy”) is valid for downloading and use
                        all of application from the Photo Studio App. The person downloading
                        and/or using the App is in the following referred to
                        referred to as the “User”.
                      </p>
                      <p>
                          The Photo Studio of YouPro, AmazinPro - uses  APIs for Image creating AI functionalities. 
                        </p>
                      <br />
                      <h4>
                        Photo Studio  don't collect any information of
                        user.
                      </h4>
                      <br />
                      <p>
                        In order to maintain funds in the product
                        may have advertising and purchase packages, looking
                        forward to your understanding and support. In
                        Application, We don't collect any information of user.
                      </p>
                      <br />
                      <p>
                        <strong>Safe and Convenient</strong>
                      </p>
                      <br />
                      <p>
                        We, Photo Studio  is very useful and safety. it is important
                        that you as a User of our App feel secure in using the
                        App, and it is important to us to safeguard your
                        personal information and integrity. Here is a brief
                        summary of how we Store, protect, process and use
                        personal data in accordance with the General Data
                        Protection Regulation (GDPR).
                      </p>
                      <p>
                        <strong> User-generated Content & Copyright</strong>
                      </p>
                      <br />
                      <ul>
                        <li>
                        Content created by users is their sole responsibility.
                        </li>
                        <li>
                        Photo Studio does not endorse or take responsibility for user-generated content.
                        </li>
                        <li>
                         Users should respect copyright laws and avoid using copyrighted materials without proper authorization.
                        </li>
                        <li>
                         The content is provided by the user, We & the application is not responsible for the copyright of the photo.
                        </li>
                        </ul>
                        <br/>
                      
                      <p>
                        By downloading the App you agree to our Privacy Policy
                        and our use of your personal data.
                      </p>
                      <br />
                      <h4>
                        <strong>Advertisement & Purchasing</strong>
                      </h4>

                      <br />
                      <p>
                        Purchases within the App are done through App Store or
                        Google Play. Upon purchase, Photo Studio  receives a
                        transaction identification number from App Store or
                        Google Play, in order to, if needed, verify the status
                        of the purchase order from a subscriber at App Store or
                        Google Play. Photo Studio  have no possibility to connect or
                        link this transaction id to the data collected from a
                        single User, and does not.
                      </p>
                      <p>
                      Once you have purchased or subscribed, paid, We are not responsible for refunds because the payment is handled by Google and Apple.
                        </p>  
                      <br />

                      <br />

                      <p>
                        <strong>
                          This Privacy Policy have been updated and published by
                          Photo Studio  July,26,2023
                        </strong>
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
      </div>
    );
  }
}

export default PhotoStudioPrivacyPolicy;
